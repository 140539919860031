import React, { createContext, useState, useEffect } from 'react';
import api from '../services/api';
import { getOneUser, getTariffs } from '../functions/functions';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [tariff, setTariff] = useState({});

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      checkAuthentication();
    }
  }, []);

  const checkAuthentication = async () => {
    try {
      const token = localStorage.getItem('token');
      await api.post('/auth/validate-token', { token });
      setIsAuthenticated(true);
      toggleBalance();
    } catch (error) {
      localStorage.clear();
      window.location.href = '/';
    }
  };

  const toggleBalance = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const userData = await getOneUser();
        const tariff = await getTariffs(1, 10, userData.profile);
        setUser(userData);
        setTariff(tariff);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('tariff', JSON.stringify(tariff));
      } catch (error) {
        //console.error('Erro ao buscar usuário:', error);
      }
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, tariff, toggleBalance, checkAuthentication }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
