export const columns = [
    { id: 'id', label: 'ID' },
 /*    { id: 'createdAt', label: 'Data' }, */
    { id: 'userData.name', label: 'Nome' },
    { id: 'userData.document', label: 'Documento' },
    /* { id: 'email', label: 'E-mail' },
    { id: 'userData.telephone', label: 'Telefone' }, */
    { id: 'configData.balance', label: 'Saldo' },
    { id: 'keyPix', label: 'Chave PIX' },
  ];
  