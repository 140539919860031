import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate } from 'react-router-dom';
import {
  styled,
  createTheme,
  CssBaseline,
  Box,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  Grid,
  Drawer,
  Button,
  InputLabel,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ExitToApp,
} from '@mui/icons-material';

import * as Format from './functions/Format';
import Login from './pages/Login';
import Home from './pages/home/Home';
import Contas from './pages/contas/Contas';
import Cadastrar from './pages/cadastrar/Cadastrar';
import Extrato from './pages/extrato/Extrato';
import Isencao from './pages/isencao/Isencao';
import Transferencia from './pages/transferencia/Transferencia';
import Tarifa from './pages/tarifa/Tarifa';
import Relatorios from './pages/relatorios/Relatorios';
import Painel from './pages/painel/Painel';
import NotFound from './pages/NotFound';
import { AuthProvider, AuthContext } from './contexts/AuthContext';

const screens = [
  { name: 'Home', component: Home, path: '/home', icon: "apps", requireMaster: false },
  { name: 'Contas', component: Contas, path: '/contas', icon: "apps", requireMaster: false },
  { name: 'Cadastrar', component: Cadastrar, path: '/cadastrar', icon: "apps", requireMaster: false },
  { name: 'Extrato', component: Extrato, path: '/extrato', icon: "apps", requireMaster: false },
  { name: 'Isenção', component: Isencao, path: '/isencao', icon: "apps", requireMaster: false },
  { name: 'Transferência', component: Transferencia, path: '/transferencia', icon: "apps", requireMaster: false },
  { name: 'Tarifa', component: Tarifa, path: '/tarifa', icon: "apps", requireMaster: true },
  { name: 'Relatório', component: Relatorios, path: '/relatorio', icon: "apps", requireMaster: true },
  { name: 'Painel', component: Painel, path: '/painel', icon: "apps", requireMaster: true },
];

const drawerWidth = 240;

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomDrawer = styled(Drawer)(({ theme, open }) => ({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    ...(open && {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const defaultTheme = createTheme();

const AppRoutes = ({ user, tariff, checkAuthentication }) => (
  <Routes>
    {screens.map((screen, index) => (
      // Exibe a rota somente se o perfil for 'master', ou se 'requireMaster' for false
      (!screen.requireMaster || (user?.accountData?.length > 0 && user.accountData[0]?.profile === 'master')) && (
        <Route
          key={index}
          path={screen.path}
          element={<screen.component user={user} tariff={tariff} checkAuthentication={checkAuthentication} />}
        />
      )
    ))}
  </Routes>
);

const Dashboard = () => {
  const [open, setOpen] = useState(true);
  const { user, tariff, checkAuthentication } = useContext(AuthContext);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <CssBaseline />
      <CustomAppBar position="absolute" open={open}>
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{ marginRight: '36px', ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            {process.env.REACT_APP_NAME}
          </Typography>
          <IconButton color="inherit" onClick={toggleExit}>
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </CustomAppBar>
      <CustomDrawer variant="persistent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: [0],
          }}
        >
          {user && (<Button
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              outline: 'none',
            }}
            onClick={checkAuthentication}
          >
            <Typography variant="caption" style={{ top: 17 }}>
              {"ID " + user?.id}
            </Typography>
            <Typography variant="caption" style={{ top: 4 }}>
              {user?.userData?.name?.split(' ')[0]}
            </Typography>
            <Typography variant="caption" style={{ top: -8 }}>
              {"SALDO " + Format.converterCentavosParaReais(user?.configData?.balance)}
            </Typography>
          </Button>)}
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {screens.map((screen, index) => (
            // Exibe o nome no menu somente se o perfil for 'master', ou se 'requireMaster' for false
            (!screen.requireMaster || (user?.accountData?.length > 0 && user.accountData[0]?.profile === 'master')) && (
              <NavLink key={index} to={screen.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItemButton>
                  <ListItemText primary={screen.name} />
                </ListItemButton>
              </NavLink>
            )
          ))}
        </List>
      </CustomDrawer>
      <Grid
        sx={{
          marginLeft: (theme) => (open ? theme.spacing(30) : 0),
          transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        }}
        container
        spacing={3}
      >
        <Toolbar />
        <AppRoutes user={user} tariff={tariff} checkAuthentication={checkAuthentication} />
      </Grid>
    </Box>
  );
};

const App = () => (
  <Router>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<Dashboard />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
  </Router>
);

export default App;
