import api from '../services/api';
const token = localStorage.getItem('token');


const getUsers = async ({ page, pageSize, managerId, sortField, sortOrder, onlyManagerAccounts }) => {
    try {
        // Construir o objeto de parâmetros dinamicamente
        const params = {};

        if (page) params.page = page;                 // Adiciona o parâmetro page se for fornecido
        if (pageSize) params.pageSize = pageSize;     // Adiciona o parâmetro pageSize se for fornecido
        if (managerId) params.managerId = managerId;  // Adiciona o parâmetro managerId se for fornecido
        if (sortField) params.sortField = sortField;  // Adiciona o parâmetro sortField se for fornecido
        if (sortOrder) params.sortOrder = sortOrder;  // Adiciona o parâmetro sortOrder se for fornecido
        if (onlyManagerAccounts) params.onlyManagerAccounts = onlyManagerAccounts;  // Adiciona o parâmetro onlyManagerAccounts se for fornecido

        // Faz a requisição com os parâmetros dinamicamente construídos
        const response = await api.get('/user/all-user', { params });

        return response.data;
    } catch (error) {
        //console.error('Erro ao buscar usuários:', error.message); // Loga o erro para diagnóstico
        throw error; // Relança o erro para tratamento posterior
    }
};


const getOneUser = async () => {
    try {
        const response = await api.get('/user/one-user');
        return response.data;
    } catch (error) {
        //console.error('Erro ao buscar usuários:', error);
        throw error;
    }
};

const validateToken = async () => {
    try {
        const response = await api.post('/auth/validate-token', { token });
        return response.data;
    } catch (error) {
        //console.error('Erro ao validar toke:', error);
        localStorage.removeItem('token');
        window.location.href = '/';
    }
};

const login = async (email, password) => {
    try {
        const response = await api.post('/auth/login', { email, password });
        return response.data;
    } catch (error) {
        return error;
    }
};

const cadastrarTarifaPadrao = async (chaveTarifa, descricao, valor, tipo_de_cobranca) => {
    try {
        const response = await api.post('/tariff/create-tariff', { tariffkey: chaveTarifa, description: descricao, value: valor, typeofcharge: tipo_de_cobranca });
        return response.data;
    } catch (error) {
        return error;
    }
};

const alterarTarifaPadrao = async (chaveTarifa, descricao, valor, tipo_de_cobranca, dadosAcao, originaccount = null, tariffId = null) => {
    // Verifica se os valores foram alterados
    const newTariffKey = chaveTarifa !== dadosAcao.tariffkey ? chaveTarifa : dadosAcao.tariffkey;
    const newDescription = descricao !== dadosAcao.description ? descricao : dadosAcao.description;
    const newValue = valor !== dadosAcao.value ? valor : '';
    const newTypeOfCharge = tipo_de_cobranca !== dadosAcao.typeofcharge ? tipo_de_cobranca : dadosAcao.typeofcharge;

    try {
        // Envia a requisição para a API com os valores alterados
        const response = await api.put('/tariff/alter-tariff', {
            tariffkey: newTariffKey,
            description: newDescription,
            value: newValue,
            typeofcharge: newTypeOfCharge,
            id: dadosAcao.tariffId == null ? dadosAcao.id : dadosAcao.tariffId,
            originaccount,
            tariffId,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};


const getTariffs = async (page, pageSize, profile, id) => {
    try {
        const response = await api.get('/tariff/all-tariff', {
            params: {
                page,
                pageSize,
                profile,
                id
            }
        });
        return response.data;
    } catch (error) {
        //console.error('Erro ao buscar usuários:', error);
        throw error;
    }
};

const getTariffUser = async (id) => {
    try {
        const response = await api.get('/user/one-user');
        return response.data;
    } catch (error) {
        //console.error('Erro ao buscar usuários:', error);
        throw error;
    }
};

const kyc = async (document) => {
    try {
        const response = await api.post('/services/kyc', { document });
        return response.data;
    } catch (error) {
        return error;
    }
};

const zipcode = async () => {
    try {
        const response = await api.get('/services/zipcode');
        return response.data;
    } catch (error) {
        return error;
    }
};

const createUser = async ({ demand, tariffkey, email, password, userData, addressData, accountData }) => {
    try {
        const response = await api.post('/user/create-user', { demand, tariffkey, email, password, userData, addressData, accountData });
        return response.data;
    } catch (error) {
        return error;
    }
};

const getAccountSummaryUser = async (managerId) => {
    try {
        const response = await api.get('/user/summary', {
            params: {
                managerId: managerId
            }
        });
        return response.data;
    } catch (error) {
        //console.error('Erro ao buscar resumo das contas:', error);
        throw error;
    }
};

const getManager = async () => {
    try {
        const response = await api.get('/user/all-manager');
        return response.data;
    } catch (error) {
        //console.error('Erro ao buscar resumo das contas:', error);
        return [];
    }
};

const getypeMov = async () => {
    try {
        const response = await api.get('/externalmovement/type-movements');
        return response.data;
    } catch (error) {
        //console.error('Erro ao buscar resumo das contas:', error);
        return [];
    }
};


const fetchExtrato = async (account, fromDate, toDate, page, pageSize) => {
    try {
        const response = await api.get('/externalmovement/all-externalmovement', {
            params: {
                account,
                fromDate,
                toDate,
                page,
                pageSize
            }
        });
        return response.data; // Retorna os dados do extrato
    } catch (error) {
        //console.error('Erro ao buscar o extrato:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const fetchExtratoMaster = async (managerId, fromDate, toDate, page, pageSize, typemovement, operation, managerMovId, csvT) => {
    try {
        const response = await api.get('/externalmovement/master-externalmovement', {
            params: {
                managerId,
                fromDate,
                toDate,
                page,
                pageSize,
                typemovement,
                operation,
                managerMovId,
                csv: csvT
            }
        });

        //console.log(response.data)
        return response.data; // Retorna os dados do extrato
    } catch (error) {
        //console.error('Erro ao buscar o extrato:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const createSplitExternalMovement = async (payload) => {
    try {
        const response = await api.post('/externalmovement/create-splitexternalmovement', payload);
        return response.data; // Retorna a resposta da criação do split external movement
    } catch (error) {
        //console.error('Erro ao criar split external movement:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};


const getUserDetail = async (userId) => {
    try {
        // Verifique se o userId está definido antes de fazer a requisição
        if (!userId) {
            throw new Error('userId is required');
        }

        // Faz a requisição GET com o userId como parâmetro
        const response = await api.get('/user/one-user', {
            params: {
                id: userId // Envia o userId como um parâmetro chamado 'id'
            }
        });

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao obter detalhes do usuário:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};


const getQrCodeUser = async (userId) => {
    try {
        // Verifique se o userId está definido antes de fazer a requisição
        if (!userId) {
            throw new Error('userId is required');
        }

        // Faz a requisição GET com o userId como parâmetro
        const response = await api.get('/keypix/create-qrcode-keypix', {
            params: {
                id: userId // Envia o userId como um parâmetro chamado 'id'
            }
        });

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao obter detalhes do usuário:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const createKeyPix = async ({ userId, typekey }) => {

    try {
        // Verifique se o userId está definido antes de fazer a requisição
        if (!userId) {
            throw new Error('userId is required');
        }

        // Faz a requisição GET com o userId como parâmetro
        const response = await api.post('/keypix/create-keypix', {

            originaccount: userId,
            typekey: typekey

        });
        //console.log(response)
        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao obter detalhes do usuário:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const deleteKeyPix = async (chavePix) => {
    try {
        // Verifique se a chavePix está definida antes de fazer a requisição
        if (!chavePix) {
            throw new Error('chavePix is required');
        }

        // Faz a requisição DELETE com a chavePix como parâmetro
        const response = await api.delete(`/keypix/delete-keypix/${chavePix}`);

        //console.log('Chave Pix deletada com sucesso:', response.data);

        // Retorna os dados recebidos na resposta (opcional)
        return response.data;
    } catch (error) {
        //console.error('Erro ao deletar a chave Pix:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const updateAccountDetails = async (chavePix) => {
    try {
        // Verifique se a chavePix está definida antes de fazer a requisição
        if (!chavePix) {
            throw new Error('chavePix is required');
        }

        // Faz a requisição DELETE com a chavePix como parâmetro
        const response = await api.delete(`/keypix/delete-keypix/${chavePix}`);

        //console.log('Chave Pix deletada com sucesso:', response.data);

        // Retorna os dados recebidos na resposta (opcional)
        return response.data;
    } catch (error) {
        //console.error('Erro ao deletar a chave Pix:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const createKeyPixDirect = async ({ tipoChave, chave, userId }) => {

    //console.log({ tipoChave, chave, userId })

    try {
        // Verifique se o userId está definido antes de fazer a requisição
        if (!userId) {
            throw new Error('userId is required');
        }

        // Faz a requisição GET com o userId como parâmetro
        const response = await api.post('/keypix/create-keypix-direct', {
            chavePix: chave,
            originaccount: userId,
            typekey: tipoChave
        });

        //console.log(response)
        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao obter detalhes do usuário:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const updateAccountStatus = async (userId, newStatus) => {
    try {
        // Verifique se o accountId está definido antes de fazer a requisição
        if (!userId) {
            throw new Error('accountId is required');
        }

        // Faz a requisição POST para atualizar o status da conta
        const response = await api.post(`/user/account/status`, {
            userId: userId,
            status: newStatus
        });

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao atualizar o status da conta:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const updateBalanceAccount = async (account) => {
    try {
        // Faz a requisição POST para atualizar o saldo da conta
        const response = await api.post(`/user/account/balance`, {
            account
        });
        //console.log(response.data);

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao atualizar o saldo da conta:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const updateUserPassword = async (userId, password) => {
    try {
        const response = await api.put('/user/alter-user/password', { userId, newPassword: password });
        return response.data;
    } catch (error) {
        //console.error('Erro ao atualizar a senha:', error);
        throw error;
    }
};

const updateUserEmail = async (userId, email) => {
    try {
        const response = await api.put('/user/alter-user/email', { userId, newEmail: email });
        return response.data;
    } catch (error) {
        //console.error('Erro ao atualizar a senha:', error);
        throw error;
    }
};


const updateUserCanTransfer = async (userId) => {
    try {
        const response = await api.put('/user/alter-user/cantransfer', { userId });
        //console.log(response);
        return response.data;
    } catch (error) {
        //console.error('Erro ao atualizar a senha:', error);
        throw error;
    }
};

const createExemptionAccount = async (payload) => {
    try {
        // Faz a requisição POST para atualizar o saldo da conta
        const response = await api.post(`/exemption/create-exemption`, payload);

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao criar a isenção:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const getAllExemptions = async (userId, status) => {
    try {
        // Verifique se o userId está definido antes de fazer a requisição
        if (!userId) {
            throw new Error('userId é obrigatório');
        }

        // Faz a requisição GET com o userId e status como parâmetros
        const response = await api.get('/exemption/all-exemption', {
            params: {
                managerId: userId, // Envia o userId como 'managerId'
                status: status !== undefined ? status : null // Envia o status se estiver definido, caso contrário, envia null
            }
        });

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error(`Erro ao obter isenções para o usuário com ID ${userId}:`, error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};


const updateExemptions = async (ids, status) => {
    try {

        // Faz a requisição GET com o userId e status como parâmetros
        const response = await api.post('/exemption/update-exemption',
            {
                ids: ids, // Envia o userId como 'managerId'
                status: status !== undefined ? status : null // Envia o status se estiver definido, caso contrário, envia null
            }
        );

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error(`Erro ao obter isenções para o usuário com ID ${ids}:`, error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};


const enviarKycDoc = async (Conta, kycConta) => {
    //console.log('XAccountId:', Conta.accountData[0].paymentaccount);
    //console.log('cpf:', Conta.userData.document);
    try {
        // Faz a requisição POST para o back-end com os dados da conta e imagens base64
        const response = await api.post('/services/kycDoc', {
            XAccountId: Conta.accountData[0].paymentaccount,
            cpf: Conta.userData.document,
            frontBase64: kycConta.frontBase64,
            backBase64: kycConta.backBase64,
            face1Base64: kycConta.face1Base64,
            face2Base64: kycConta.face2Base64,
            docFaceBase64: kycConta.docFaceBase64,
        });

        return response.data;
    } catch (error) {
        // Tratamento de erros da requisição
        if (error.response) {
            // O servidor retornou uma resposta com um código de status fora do intervalo 2xx
            //console.error('Erro na resposta do servidor:', error.response);
            throw new Error(`Erro no envio do KYC: ${error.response.data.message || 'Erro desconhecido'}`);
        } else if (error.request) {
            // A requisição foi feita, mas nenhuma resposta foi recebida
            //console.error('Nenhuma resposta recebida:', error.request);
            throw new Error('Nenhuma resposta do servidor. Verifique sua conexão.');
        } else {
            // Algo aconteceu ao configurar a requisição
            //console.error('Erro ao configurar a requisição:', error.message);
            throw new Error('Erro ao enviar a requisição: ' + error.message);
        }
    }
};

const consultarPix = async ({ userId, chavePix, typekey }) => {
    try {
        // Verifique se o userId está definido antes de fazer a requisição
        if (!userId) {
            throw new Error('userId is required');
        }

        // Faz a requisição para consultar a chave Pix (ajustado para consulta)
        const response = await api.post('/services/pix/consult-key', {
            userId: userId,
            chavePix: chavePix,
            typekey: typekey
        });

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao consultar detalhes da chave Pix:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};


const realizarTransferencia = async ({ user, pixKey, amount, demand, tariffkey }) => {
    try {
        // Verifica se todos os dados obrigatórios estão presentes antes de fazer a requisição
        if (!user || !pixKey || !amount) {
            throw new Error('Dados obrigatórios ausentes');
        }

        // Monta o objeto da conta de origem
        const originAccount = user.id;

        // Monta o objeto do credor (detalhes da conta do destinatário)
        const creditor = {
            name: pixKey.ownerName, // Nome do destinatário
            document: pixKey.ownerDocument, // CPF ou CNPJ do destinatário
            account: {
                ispb: pixKey.ispb, // ISPB do banco do destinatário
                branch: pixKey.accountBranch.toString().padStart(4, '0'), // Agência
                number: pixKey.accountNumber, // Conta
                type: pixKey.accountType // Tipo da conta (CACC, SVGS, etc.)
            }
        };

        // Monta o objeto de dados para a requisição, de acordo com o tipo de iniciação (DICT)
        const data = {
            amount, // Valor da transferência
            initiationType: 'DICT', // Exemplo: altere para QRDN ou QRES conforme o caso
            creditor, // Detalhes do credor
            pixKey: pixKey.id, // Chave Pix
            originAccount, // Conta de onde o dinheiro será retirado
            endToEndId: pixKey.endToEndId, // Necessário para tipo DICT
            demand,
            tariffkey
        };

        // Faz a requisição POST para criar a transferência PIX
        const response = await api.post('/services/pix/transfer', data);

        // Verifica se a resposta da API foi bem-sucedida e retorna os dados
        return response.data;
    } catch (error) {
        // Log do erro para depuração
        //console.error('Erro ao realizar transferência:', error);
        // Lança o erro para ser tratado pela função chamadora
        throw error;
    }
};

// Função para realizar a transferência da conta gerente para a conta filha e pagar o Pix
const realizarTransferenciaComPix = async ({ managerId, childId, pixKey, pixAmount, creditor, initiationType }) => {
    try {
        // Verifica se todos os dados obrigatórios estão presentes
        if (!managerId || !childId || !pixKey || !pixAmount || !creditor) {
            throw new Error('Dados obrigatórios ausentes');
        }

        // Monta os dados para enviar na requisição ao backend
        const data = {
            managerId,  // ID da conta gerente
            childId,    // ID da conta filha
            pixAmount,  // Valor da transferência (Pix "Copia e Cola")
            creditor,   // Informações do credor (nome, documento, etc.)
            pixKey: pixKey.id,  // Chave Pix
            endToEndId: pixKey.endToEndId, // EndToEndId para o tipo DICT (Pix "Copia e Cola")
            initiationType
        };

        // Faz a requisição POST para o backend, chamando a rota que faz a transferência e pagamento
        const response = await api.post('/services/pix/transfer-manager-child-pix', data);

        // Verifica se a resposta da API foi bem-sucedida e retorna os dados
        if (response.data && response.data.sucesso) {
            return response.data;
        } else {
            throw new Error(response.data.mensagem || 'Erro ao realizar transferência e pagamento Pix.');
        }
    } catch (error) {
        // Log do erro para depuração
        //console.error('Erro ao realizar transferência e pagamento Pix:', error);
        // Lança o erro para ser tratado pela função chamadora
        throw error;
    }
};

const consultarMovimentacao = async (id) => {
    try {

        // Faz a requisição GET para buscar a movimentação pelo ID
        const response = await api.get(`/externalmovement/one-externalmovement/${id}`);

        //console.log('Movimentação consultada com sucesso:', response.data);

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao consultar a movimentação:', error);
        return 0;
    }
};

const getUserDetailDoc = async (managerId, document) => {
    try {
        // Faz a requisição GET com o userId como parâmetro
        const response = await api.get('/user/one-user-doc', {
            params: {
                managerId,
                document
            }
        });

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao obter detalhes do usuário:', error);
        return 0;
    }
};


const verificarStatusKYC = async (accountId) => {
    try {
        // Verifique se o accountId está definido antes de fazer a requisição
        if (!accountId) {
            throw new Error('accountId is required');
        }

        // Faz a requisição POST para atualizar o status da conta
        const response = await api.post(`/user/account/status-kyc`, {
            accountId
        });

        //console.log(response.data);

        // Retorna os dados recebidos na resposta
        return response.data;
    } catch (error) {
        //console.error('Erro ao atualizar o status da conta:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const executeTransfer = async (accountFromId, accountToId, transferValue) => {
    try {
        const response = await api.post('/externalmovement/create-transfer-externalmovement', { accountFromId, accountToId, transferValue });
        return response.data; // Retorna a resposta da criação do split external movement
    } catch (error) {
        //console.error('Erro ao criar split external movement:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const getComprovanteByEndToEnd = async (payload) => {
    try {
        const response = await api.post('/externalmovement/create-transfer-externalmovement', payload);
        return response.data; // Retorna a resposta da criação do split external movement
    } catch (error) {
        //console.error('Erro ao criar split external movement:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

const getMovimentsService = async (startDate, endDate, itemsPerPage, page, paymentaccount) => {
    try {
        const response = await api.get('/externalmovement/all-externalmovement-lq', {
            params: {
                startDate,
                endDate,
                itemsPerPage,
                page,
                paymentaccount
            }
        });

        return response.data;
    } catch (error) {
        //console.error('Erro ao consultar movimentos:', error);
        return null;
    }
};


const GerarOperator = async (name, account, XAccountId) => {
    try {
        // Faz a requisição POST para atualizar o saldo da conta
        const response = await api.post(`/user/account/operator`, {
            name, 
            account,
            XAccountId
        });

        // Retorna os dados recebidos na resposta
        //console.log(response.data);
        return response.data;
    } catch (error) {
        //console.error('Erro ao atualizar o saldo da conta:', error);
        throw error; // Lança o erro para ser tratado na função que chama essa
    }
};

export {GerarOperator, getMovimentsService,  getComprovanteByEndToEnd, executeTransfer, updateUserCanTransfer, getypeMov, getManager, fetchExtratoMaster, updateUserEmail, verificarStatusKYC, getUserDetailDoc, consultarMovimentacao, realizarTransferenciaComPix, consultarPix, realizarTransferencia, enviarKycDoc, updateExemptions, getAllExemptions, createExemptionAccount, updateUserPassword, updateBalanceAccount, updateAccountStatus, createKeyPixDirect, updateAccountDetails, createKeyPix, deleteKeyPix, getQrCodeUser, getUserDetail, createSplitExternalMovement, fetchExtrato, login, getOneUser, getUsers, createUser, validateToken, cadastrarTarifaPadrao, alterarTarifaPadrao, getTariffs, getTariffUser, kyc, zipcode, getAccountSummaryUser };