import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    Container,
    CircularProgress,
    Snackbar,
    Alert
} from '@mui/material';
import InputMask from "react-input-mask";
import * as Funcoes from '../../functions/functions';
import * as Format from "../../functions/Format";
import { fetchContas, fetchKYC, fetchCEP, createAccount } from './utils/apiCalls';
import * as Formatar from './utils/format';
import axios from 'axios';
import { bancos } from '../../components/isbp'; // Importa a lista de bancos com ISPB
import { ceps } from '../../components/ceps'; // Importa a lista de bancos com ISPB

// Função para gerar email fictício
const gerarEmailFicticio = (nome) => {
    const provedoresValidos = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'icloud.com'];
    const provedorAleatorio = provedoresValidos[Math.floor(Math.random() * provedoresValidos.length)];
    return `${nome.replace(/\s+/g, '').toLowerCase()}@${provedorAleatorio}`;
};

// Função para gerar telefone fictício
const gerarTelefoneFicticio = () => {
    const DDDs = ['11', '21', '31', '41', '51', '61', '71', '81', '91'];
    const DDD = DDDs[Math.floor(Math.random() * DDDs.length)];
    const numeroBase = Math.floor(Math.random() * 900000000 + 100000000).toString();
    return `${DDD}${numeroBase}`;
};


// Função para gerar endereço fictício com CEP válido
const gerarEnderecoFicticio = async () => {
    let enderecoValido = await Funcoes.zipcode();
    return {
        cep: enderecoValido.cep,
        endereco: enderecoValido.logradouro || '',
        bairro: enderecoValido.bairro || '',
        cidade: enderecoValido.estado || '',
        estado: enderecoValido.uf || '',
        numero: Math.floor(Math.random() * 1000) + 1,
        complemento: enderecoValido.complemento
    };
};

// Função para buscar o nome do banco pelo ISPB
const buscarNomeBancoPorISPB = (ispb) => {
    const banco = bancos.find((banco) => banco.ispb === ispb);
    return banco ? banco.name : 'Banco não encontrado';
};

const generateRandomBirthDate = () => {
    const currentDate = new Date();
    const minYear = currentDate.getFullYear() - 100; // Limite de idade máxima (100 anos atrás)
    const maxYear = currentDate.getFullYear() - 18;  // Limite de idade mínima (18 anos atrás)

    // Gera um ano aleatório entre minYear e maxYear
    const randomYear = Math.floor(Math.random() * (maxYear - minYear + 1)) + minYear;

    // Gera um mês aleatório entre 0 e 11 (Janeiro a Dezembro)
    const randomMonth = Math.floor(Math.random() * 12);

    // Gera um dia aleatório entre 1 e 28 (para evitar problemas com meses que têm menos de 31 dias)
    const randomDay = Math.floor(Math.random() * 28) + 1;

    // Cria a data aleatória
    const randomDate = new Date(randomYear, randomMonth, randomDay);

    // Formata a data no formato YYYY-MM-DD
    return randomDate.toISOString().split('T')[0];
};

const isDateFormatted = (dateString) => {
    // Expressão regular para verificar se a data está no formato YYYY-MM-DD
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormatRegex.test(dateString);
};

const Cadastrar = ({ tariff, checkAuthentication }) => {
    // Estados separados para cada campo
    const [documento, setDocumento] = useState('');
    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [pesquisarCPF, setPesquisarCPF] = useState(false);
    const [loadCadastrar, setLoadCadastrar] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [nomeBanco, setNomeBanco] = useState('');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [tariffList, setTariff] = useState(Array.isArray(tariff) ? tariff : JSON.parse(localStorage.getItem('tariff')));

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        async function fetchDatacheck() {
            await checkAuthentication();
        }
        fetchDatacheck();
    }, []);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSearchPixKey = async (cpf) => {
        try {
            const response = await Funcoes.consultarPix({ userId: user.id, chavePix: cpf, typekey: 1 });
            const detalhesPix = response?.data;
            const nomeBanco = buscarNomeBancoPorISPB(detalhesPix?.ispb || '');

            const status = !!detalhesPix?.id; // Verifica se a chave Pix foi encontrada

            // Retornando um objeto com o status e nomeBanco
            return { status, nomeBanco };
        } catch (error) {
            return { status: false, nomeBanco: '' }; // Em caso de erro, retorna status false e nomeBanco vazio
        }
    };

    const buscarKYC = async () => {
        const tarifaItem = tariffList.find((item) => item.tariffkey === "tarifa_criar_conta_filha");
        //console.log('saldo: ' + user.configData?.balance);
        //console.log('tarifa: ' + tarifaItem.value);
        if (user.configData?.balance < tarifaItem.value) {
            setSnackbarOpen(true);
            setSnackbarMessage('Saldo insuficiente');
            setSnackbarSeverity('error');
            setPesquisarCPF(false);
            return;
        }
        setPesquisarCPF(true);
        setShowError(false);

        if (!documento || !Formatar.validar_cpf(documento)) {
            setSnackbarOpen(true);
            setSnackbarMessage('Por favor, verifique o CPF.');
            setSnackbarSeverity('error');
            setPesquisarCPF(false);
            return;
        }

        if (user.accountData[0]?.profile !== 'master') {
            // Chama a função para buscar a chave Pix e obter status e nomeBanco
            const { status, nomeBanco } = await handleSearchPixKey(documento.replace(/\D/g, ''));

            // Verifica se a chave Pix já existe
            if (status) {
                setSnackbarOpen(true);
                setSnackbarMessage(`CPF já cadastrado no ${nomeBanco}`);
                setSnackbarSeverity('error');
                setPesquisarCPF(false);
                return;
            }
        }

        try {
            const response = await fetchKYC(documento);

            if (response?.dadosCadastro?.status) {
                const dadosCompletos = {
                    nome: response?.dadosCadastro?.nome,
                    documento,
                    nomeMae: response?.dadosCadastro?.nomeMae,
                    dataNascimento: response?.dadosCadastro?.dataNascimento || generateRandomBirthDate(),
                    ...await gerarEnderecoFicticio(),
                    email: response?.dadosCadastro?.email || gerarEmailFicticio(response?.dadosCadastro?.nome),
                    sexo: response?.dadosCadastro?.sexo,
                    telefone: response?.dadosCadastro?.telefone || gerarTelefoneFicticio()
                };
                criarConta(dadosCompletos);
                setPesquisarCPF(false);
            } else {
                // Caso o status seja falso, exibe mensagem de erro
                setSnackbarOpen(true);
                setSnackbarMessage('Erro: Status do cadastro inválido.');
                setSnackbarSeverity('error');
                setPesquisarCPF(false);
            }
        } catch (error) {
            setSnackbarOpen(true);
            setSnackbarMessage('Erro ao buscar KYC');
            setSnackbarSeverity('error');
            setPesquisarCPF(false);
        }
    };

    const criarConta = async (dadosCompletos) => {
        setLoadCadastrar(true);

        if (!dadosCompletos.nome || !dadosCompletos.documento) {
            setSnackbarOpen(true);
            setSnackbarMessage('Por favor, preencha todos os campos obrigatórios.');
            setSnackbarSeverity('error');
            setLoadCadastrar(false);
            return;
        }

        try {

            const dataNascimento = dadosCompletos.dataNascimento;

            // Verifica se a data está formatada, caso não, formata a data
            const dataFormatada = isDateFormatted(dataNascimento)
                ? dataNascimento
                : Formatar.formatarDate(dataNascimento);

            const userData = {
                document: dadosCompletos.documento.replace(/\D/g, ""),
                name: dadosCompletos.nome,
                telephone: dadosCompletos.telefone.replace(/\D/g, ""),
                mother: dadosCompletos.nomeMae,
                father: '',
                birth: dataFormatada,
                sex: dadosCompletos.sexo
            };

            const addressData = {
                zipcode: dadosCompletos.cep.replace(/\D/g, ""),
                address: dadosCompletos.endereco,
                number: dadosCompletos.numero,
                complement: dadosCompletos.complemento,
                district: dadosCompletos.bairro,
                city: dadosCompletos.cidade,
                state: dadosCompletos.estado
            };

            const accountData = {
                managerId: user.id,
                profile: user.accountData[0]?.profile === 'master' ? 'maneger' : 'bet'
            };


            const response = await createAccount({
                demand: user.accountData[0]?.profile === 'master' ? 0 : 1,
                tariffkey: "tarifa_criar_conta_filha",
                email: dadosCompletos.email,
                userData,
                addressData,
                accountData
            });

            //console.log(response);

            if (response.success) {
                setDocumento('');
                setSnackbarOpen(true);
                setSnackbarMessage('Conta criada com sucesso!');
                setSnackbarSeverity('success');
                setLoadCadastrar(false);
                await checkAuthentication();
            }
            else {
                setSnackbarOpen(true);
                setSnackbarMessage('Erro ao criar conta');
                setSnackbarSeverity('error');
                setLoadCadastrar(false);
            }
        } catch (error) {
            setSnackbarOpen(true);
            setSnackbarMessage('Erro ao criar conta');
            setSnackbarSeverity('error');
            setLoadCadastrar(false);
        }
    };


    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
                <Typography variant="h4" sx={{ ml: 2 }}>Carregando...</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 14, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ flexGrow: 1, padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            {'Preencha o CPF para cadastrar um apostador. Tarifa para criar a conta ' + (() => {
                                const tarifaItem = tariffList.find((item) => item.tariffkey === "tarifa_criar_conta_filha");
                                return tarifaItem ? Format.converterCentavosParaReais(tarifaItem.value) : '';
                            })()}
                        </Typography>
                        <Box sx={{ flexGrow: 1, mb: 4 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={4}>
                                    <InputMask
                                        mask="999.999.999-99"
                                        value={documento}
                                        onChange={(event) => setDocumento(event.target.value)}
                                    >
                                        {(inputProps) => <TextField size="small" {...inputProps} variant="outlined" fullWidth margin="normal" label="CPF" />}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        fullWidth
                                        sx={{ height: '40px', marginTop: '16px', fontSize: '0.875rem' }}
                                        color="success"
                                        onClick={buscarKYC}
                                        disabled={pesquisarCPF || loadCadastrar}
                                    >
                                        {pesquisarCPF ? <CircularProgress size={24} /> : "Cadastrar"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        {showError && <Typography color="error">{mensagem}</Typography>}
                    </Box>
                </Grid>
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Cadastrar;
