import React, { useState, useEffect } from 'react';
import { fetchContas } from './utils/apiCalls';
import DataTable from './DataTable';
import Loading from './Loading';
import {
  Box,
  Container,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography
} from '@mui/material';
import * as Funcoes from '../../functions/functions';

const Contas = ({ user, checkAuthentication }) => {
  const getInitialState = () => ({
    user: JSON.parse(localStorage.getItem('user')),
    alertMessage: '',
    page: 0,
    rowsPerPage: 25, // Configuração padrão de 10 registros por página
    orderBy: 'id', // Coluna padrão para ordenação
    order: 'desc', // Ordem ascendente
    show: false,
    loadTela: true,
    tabelaIni: [],
    totalItems: 0,
    tabelaFiltro: [],
    mostrar_contas: true,
    search: '',
    dadosAcao: [],
    onlyManagerAccounts: false
  });

  useEffect(() => {
    async function fetchDatacheck() {
      await checkAuthentication();
    }
    fetchDatacheck();
  }, []);

  const [state, setState] = useState(getInitialState());

  
  useEffect(() => {
    setState(prevState => ({ ...prevState, user: JSON.parse(localStorage.getItem('user')) }));
  }, []);

  useEffect(() => {
    fetchData();
  }, [state.onlyManagerAccounts]);

  const fetchcheckAuthentication = async () => {
    await checkAuthentication();
  };

  const fetchData = async (newPage = state.page, newRowsPerPage = state.rowsPerPage, sortField = state.orderBy, sortOrder = state.order, onlyManagerAccounts = state.onlyManagerAccounts) => {
    const { user } = state;

    if (sortField == "configData.balance") {
      sortField = "balance"
    }

    if (sortField == "id") {
      sortField = "user_createdAt"
    }

    if (sortField == "userData.name") {
      sortField = "userdata_name"
    }

    if (sortField == "userData.document") {
      sortField = "userdata_document"
    }


    //console.log(sortField);

    try {
      const users = await fetchContas(user.id, newPage + 1, newRowsPerPage, sortField, sortOrder, onlyManagerAccounts); // Enviando também os parâmetros de ordenação

      //console.log(users)
      setTabelaFiltro(users.data);
      setTabelaIni(users.data);
      setLoadTela(false);
      setTotalItems(users.totalItems);
    } catch (error) {
      setLoadTela(false);
    }
  };



  const pesquisar = async (search) => {
    const searchFormatted = search.replace(/\D/g, ""); // Remove qualquer caractere que não seja dígito

    // Tenta encontrar o documento localmente na tabelaIni
    const dadosFiltrados = state.tabelaIni.filter((item) => (
      item.userData.document.includes(searchFormatted)
    ));

    if (dadosFiltrados.length > 0) {
      // Se encontrar localmente, atualiza a lista filtrada
      setTabelaFiltro(dadosFiltrados);
    } else {
      // Verifica se o searchFormatted é um CPF (11 dígitos) ou CNPJ (14 dígitos)
      if (searchFormatted.length === 11 || searchFormatted.length === 14) {
        // Caso seja um CPF ou CNPJ, faz a busca no servidor
        const managerId = state.user.id;
        try {
          const response = await Funcoes.getUserDetailDoc(managerId, searchFormatted);

          if (response && response.data) {
            // Se encontrar o usuário no servidor, atualiza tabelaIni e tabelaFiltro
            const newList = [...state.tabelaIni, response.data];
            // setTabelaIni(newList);
            setTabelaFiltro([response.data]);
          } else {
            //console.log("Usuário não encontrado no servidor");
          }
        } catch (error) {
          //console.error("Erro ao buscar o usuário no servidor", error);
          // Se quiser, você pode tratar o erro aqui (ex: exibir uma mensagem ao usuário)
        }
      } else {
        // Caso não seja CPF ou CNPJ válido, não faz a busca no servidor
        //console.log("Documento inválido para busca (não é CPF ou CNPJ)");
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Atualiza o estado da página
    fetchData(newPage, state.rowsPerPage); // Busca os dados para a nova página
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage); // Atualiza o número de registros por página
    setPage(0); // Reseta para a primeira página
    fetchData(0, newRowsPerPage); // Busca os dados com a nova quantidade de linhas por página
  };

  const handleRequestSort = (property) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrderBy(property);
    setOrder(newOrder);

    // Chame o fetchData para buscar os dados com a nova ordenação
    fetchData(state.page, state.rowsPerPage, property, newOrder);
  };


  // Funções para atualizar estado separadamente
  const setTabelaFiltro = (data) => setState(prevState => ({ ...prevState, tabelaFiltro: data }));
  const setTabelaIni = (data) => setState(prevState => ({ ...prevState, tabelaIni: data }));
  const setLoadTela = (load) => setState(prevState => ({ ...prevState, loadTela: load }));
  const setTotalItems = (total) => setState(prevState => ({ ...prevState, totalItems: total }));
  const setPage = (page) => setState(prevState => ({ ...prevState, page }));
  const setRowsPerPage = (rowsPerPage) => setState(prevState => ({ ...prevState, rowsPerPage }));
  const setOrderBy = (orderBy) => setState(prevState => ({ ...prevState, orderBy }));
  const setOrder = (order) => setState(prevState => ({ ...prevState, order }));

  const { loadTela, mostrar_contas, tabelaFiltro, order, orderBy, rowsPerPage, page, totalItems } = state;

  if (loadTela) {
    return <Loading />;
  } else {
    return (
      <Container maxWidth="xl" sx={{ mt: 14, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {mostrar_contas && (
              <>
                <Box sx={{ flexGrow: 1, mb: 4 }}>
                  <h2>{'Contas'}</h2>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6} sm={4}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        label="Pesquisar por CPF"
                        value={state.search}
                        onChange={(texto) => {
                          setState(prevState => ({ ...prevState, search: texto.target.value }));
                          pesquisar(texto.target.value);
                        }}
                      />
                    </Grid>
                    {
                      state.user.accountData[0]?.profile === 'master' && <Grid item xs={6} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.onlyManagerAccounts}
                              onChange={(event) => {
                                setState(prevState => ({ ...prevState, onlyManagerAccounts: event.target.checked }));
                              }}
                              name="onlyManagerAccounts"
                              color="primary"
                            />
                          }
                          label={
                            <Typography variant="body1" style={{ color: '#000' }}>
                              Somente Contas Gerente
                            </Typography>
                          }
                        />
                      </Grid>
                    }

                  </Grid>

                </Box>
                <DataTable
                  onChange={() => { }}
                  state={state}
                  setState={setState}
                  fetchcheckAuthentication={fetchcheckAuthentication}
                  user={user}
                  data={tabelaFiltro}
                  order={order}
                  orderBy={orderBy}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  count={totalItems}
                  onSort={handleRequestSort}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  onRowClick={(row) => setState(prevState => ({ ...prevState, dadosAcao: row, mostrar_contas: false, mostrar_acao: true }))}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
};

export default Contas;
