import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import logo from '../../assets/srbank.png'; // Importe o logo do banco

function Comprovante({ dados }) {
  const comprovanteRef = useRef(null);

  const gerarPNG = async () => {
    const input = document.getElementById('comprovante');
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png', 1.0);

    const link = document.createElement('a');
    link.href = imgData;
    link.download = 'comprovante.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div ref={comprovanteRef} id="comprovante" style={styles.comprovante}>
        <div style={styles.header}>
          <img src={logo} alt="Logo do Banco" style={styles.logo1} />
        </div>

      {/*   contaOrigem: {
        banco: transfer.debtor.account?.ispb || "N/A",
        cpfCnpj: transfer.debtor?.document || "N/A",
        agencia: transfer.debtor.account?.branch || "N/A",
        conta: transfer.debtor?.account?.number || "N/A", // Corrigido para acessar o número da conta
        correntista: transfer.debtor?.name || "N/A",
      },
 */}
        <h2 style={styles.titulo}>Comprovante de Envio PIX</h2>
        <p style={styles.data}>{dados?.data || "Data não disponível"}</p>

        <div style={styles.conteudo}>
          <p>
            <strong>Protocolo de Transação ID:</strong> {dados?.protocolo || "N/A"}
          </p>
          <p>
            <strong>End-To-End:</strong> {dados?.idEndToEnd || "N/A"}
          </p>

          <div style={styles.section}>
            <h3 style={styles.subtitulo}>Conta de Origem:</h3>
            {dados?.contaOrigem?.banco && (
              <p>
                <strong>ISPB:</strong> {dados.contaOrigem.banco}
              </p>
            )}
            <p>
              <strong>Agência:</strong> {dados?.contaOrigem?.agencia || "N/A"}
            </p>
            <p>
              <strong>Conta:</strong> {dados?.contaOrigem?.conta || "N/A"}
            </p>
            <p>
              <strong>Correntista:</strong> {dados?.contaOrigem?.correntista || "N/A"}
            </p>
            {dados?.contaOrigem?.cpfCnpj && (
              <p>
                <strong>CPF/CNPJ:</strong> {dados.contaOrigem.cpfCnpj}
              </p>
            )}
          </div>

          <div style={styles.section}>
            <h3 style={styles.subtitulo}>Conta de Destino:</h3>
            {dados?.contaDestino?.banco && (
              <p>
                <strong>ISPB:</strong> {dados.contaDestino.banco}
              </p>
            )}
            {dados?.contaDestino?.agencia && (
              <p>
                <strong>Agência:</strong> {dados.contaDestino.agencia}
              </p>
            )}
            {dados?.contaDestino?.conta && (
              <p>
                <strong>Conta:</strong> {dados.contaDestino.conta}
              </p>
            )}
            {dados?.contaDestino?.tipo && (
              <p>
                <strong>Tipo:</strong> {dados.contaDestino.tipo}
              </p>
            )}
            {dados?.contaDestino?.cpfCnpj && (
              <p>
                <strong>CPF/CNPJ:</strong> {dados.contaDestino.cpfCnpj}
              </p>
            )}
            {dados?.contaDestino?.nome && (
              <p>
                <strong>Nome:</strong> {dados.contaDestino.nome}
              </p>
            )}
            {dados?.contaDestino?.chave && (
              <p>
                <strong>Chave:</strong> {dados.contaDestino.chave}
              </p>
            )}
          </div>
          <div style={styles.section}>
            <p>
              <strong>Descrição cliente:</strong> {dados?.descricao || "N/A"}
            </p>
            <p>
              <strong>Data Solicitação:</strong> {dados?.dataSolicitacao || "N/A"}
            </p>
            <p>
              <strong>Data Efetivação:</strong> {dados?.dataEfetivacao || "N/A"}
            </p>
            <p>
              <strong>Valor:</strong>{dados?.valor || "N/A"}
            </p>
          </div>
        </div>
      </div>
      <div style={styles.viewBotton}>
        <button style={styles.botton} onClick={gerarPNG}>Gerar PNG</button>
      </div>
    </div>
  );
}

const styles = {
  comprovante: {
    fontFamily: "Arial, sans-serif",
    width: "100%",
    maxWidth: "600px",
    padding: "30px",
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    textAlign: "center",
    marginBottom: "40px",
    backgroundColor: "#6c757d",
    padding: "20px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    justifyContent: "space-around",
  },
  logo1: {
    width: "150px"
  },
  titulo: {
    fontSize: "2em",
    fontWeight: "bold",
    color: "#212529",
    marginBottom: "10px",
  },
  data: {
    fontSize: "1em",
    color: "#6c757d",
  },
  conteudo: {
    borderTop: "1px solid #e0e0e0",
    paddingTop: "30px",
  },
  section: {
    borderTop: "1px solid #e0e0e0",
    paddingTop: "30px",
  },
  subtitulo: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: "#212529",
    marginBottom: "10px",
  },
  viewBotton: {
    display: "flex",
    textAlign: "center",
    margin: "5px",
    padding: "10px",
    justifyContent: "center",
  },
  botton: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.3s",
    outline: "none",
    textDecoration: "none",
    alignSelf: "center",
  },
};

export default Comprovante;
