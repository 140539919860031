import React, { useRef, useState, useEffect } from 'react';
import * as Funcoes from '../../functions/functions';
import * as Formatar from '../../functions/Format';
import {
  Box,
  Button,
  Container,
  Paper,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Comprovante from '../../components/pdf/pdfnovo';


const PainelTransferencia = ({ checkAuthentication }) => {
  const [accountFromId, setAccountFromId] = useState('');
  const [accountToId, setAccountToId] = useState('');
  const [accountFrom, setAccountFrom] = useState(null);
  const [accountTo, setAccountTo] = useState(null);
  const [transferValue, setTransferValue] = useState('');
  const [endToEnd, setEndToEnd] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [loading, setLoading] = useState(false);
  const [comprovante, setComprovante] = useState(null);
  const [movementsData, setMovementsData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [mostrar, setMostrar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [isAccordionComprovanteExpanded, setIsAccordionComprovanteExpanded] = useState(false);
  const [isAccordionMovementsExpanded, setIsAccordionMovementsExpanded] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0); 
    const sevenDaysAgo = new Date(now);
    sevenDaysAgo.setDate(now.getDate() - 7);  
    return sevenDaysAgo.toISOString().split('T')[0]; // Retorna apenas a parte da data (YYYY-MM-DD)
  });
  const [endDate, setEndDate] = useState(() => {
    const now = new Date();
    now.setHours(23, 59, 59, 999); // Final do dia (23:59:59)
    return now.toISOString().split('T')[0]; // Retorna apenas a parte da data (YYYY-MM-DD)
  });
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [paymentAccount, setPaymentAccount] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [selectedComprovante, setSelectedComprovante] = useState(null);

  useEffect(() => {
    async function fetchDatacheck() {
      await checkAuthentication();
    }
    fetchDatacheck();
  }, []);

  const resetFields = () => {
    setAccountFromId('');
    setAccountToId('');
    setAccountFrom(null);
    setAccountTo(null);
    setTransferValue('');
  };

  useEffect(() => {
    if (!isAccordionExpanded) {
      resetFields();
    }
  }, [isAccordionExpanded]);

  const buscarContas = async () => {
    try {
      const fromResponse = await Funcoes.getUserDetail(accountFromId);
      const toResponse = await Funcoes.getUserDetail(accountToId);
      setAccountFrom(fromResponse);
      setAccountTo(toResponse);
    } catch (error) {
      setSnackbarMessage('Erro ao buscar contas');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleTransfer = async () => {
    if (!accountFromId || !accountToId || !transferValue) {
      setSnackbarMessage('Por favor, preencha todos os campos para transferência');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      await Funcoes.executeTransfer(accountFromId, accountToId, transferValue);
      setSnackbarMessage('Transferência realizada com sucesso');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      resetFields();
    } catch (error) {
      setSnackbarMessage('Erro ao realizar transferência');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const consultarMovimentos = async () => {
    setLoading(true);

    try {
      const response = await Funcoes.getMovimentsService(startDate, endDate, itemsPerPage, page, paymentAccount);
      console.log(response);
      setMovementsData(response);
      setTotalItems(response.data.pagination.totalItems || 0);
      setSnackbarMessage('Extrato LQ consultados com sucesso');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Erro ao consultar movimentos');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAmountChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    const floatValue = (parseInt(value, 10) / 100).toFixed(2);
    setTransferValue(floatValue);
  };

  const handleOpenComprovante = (transfer) => {
    setSelectedComprovante({
      data: Formatar.formatarDateSave(transfer.createdAt),
      protocolo: transfer.id,
      idEndToEnd: transfer.endToEnd,
      contaOrigem: {
        banco: transfer.debtor.account?.ispb || "N/A",
        cpfCnpj: transfer.debtor?.document || "N/A",
        agencia: transfer.debtor.account?.branch || "N/A",
        conta: transfer.debtor?.account?.number || "N/A", // Corrigido para acessar o número da conta
        correntista: transfer.debtor?.name || "N/A",
      },
      contaDestino: {
        banco: transfer.creditor.account?.ispb || "N/A",
        agencia: transfer.creditor.account?.branch || "N/A",
        conta: transfer.creditor.account?.number || "N/A",
        tipo: transfer.creditor.account?.type || "N/A",
        cpfCnpj: transfer.creditor?.document || "N/A",
        nome: transfer.creditor?.name || "N/A",
        chave: transfer.creditor?.key || "N/A",
      },
      descricao: "Transferência via PIX",
      dataSolicitacao: Formatar.formatarDateSave(transfer.createdAt),
      dataEfetivacao: Formatar.formatarDateSave(transfer.updatedAt),
      valor: Formatar.formatarMoeda(transfer.amount),
    });
  };

  const handleCloseComprovante = () => {
    setSelectedComprovante(null);
  };


  return (
    <Container maxWidth="xl" sx={{ mt: 14, mb: 4 }}>
      <Typography variant="h5" gutterBottom>
        Painel
      </Typography>

      {/* Accordion para Transferência Interna */}
      <Accordion
        expanded={isAccordionExpanded}
        onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Transferência Interna</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* Campos de IDs das Contas */}
            <Grid item xs={6}>
              <TextField
                label="ID Conta Pagadora"
                value={accountFromId}
                onChange={(e) => setAccountFromId(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="ID Conta Recebedora"
                value={accountToId}
                onChange={(e) => setAccountToId(e.target.value)}
                fullWidth
              />
            </Grid>

            {/* Botão para buscar contas */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={buscarContas}
                disabled={!accountFromId || !accountToId}
                fullWidth
              >
                Buscar Contas
              </Button>
            </Grid>

            {/* Exibir informações das Contas */}
            {accountFrom && (
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle1">Conta Pagadora:</Typography>
                  <Typography>Nome: {accountFrom.userData.name}</Typography>
                  <Typography>Documento: {accountFrom.userData.document}</Typography>
                  <Typography>ID Gerente: {accountFrom.accountData[0].managerId}</Typography>
                  <Typography>Saldo: R$ {Formatar.converterCentavosParaReais(accountFrom.configData.balance)}</Typography>
                </Paper>
              </Grid>
            )}

            {accountTo && (
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle1">Conta Recebedora:</Typography>
                  <Typography>Nome: {accountTo.userData.name}</Typography>
                  <Typography>Documento: {accountTo.userData.document}</Typography>
                  <Typography>ID Gerente: {accountTo.accountData[0].managerId}</Typography>
                  <Typography>Saldo: R$ {Formatar.converterCentavosParaReais(accountTo.configData.balance)}</Typography>
                </Paper>
              </Grid>
            )}

            {/* Campo para valor da transferência */}
            {accountFrom && accountTo && (
              <Grid item xs={12}>
                <TextField
                  label="Valor da Transferência"
                  value={transferValue}
                  onChange={handleAmountChange}
                  fullWidth
                  type="number"
                />
              </Grid>
            )}

            {/* Botão de Transferência */}
            {accountFrom && accountTo && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleTransfer}
                  fullWidth
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={20} />}
                >
                  {loading ? 'Transferindo...' : 'Transferir'}
                </Button>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Accordion para Consultar Extrato LQ */}
      <Accordion
        expanded={isAccordionMovementsExpanded}
        onChange={() => setIsAccordionMovementsExpanded(!isAccordionMovementsExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Consultar Extrato LQ</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Data de Início"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Data de Término"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Itens por Página"
                type="number"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value, 10))}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Página"
                type="number"
                value={page}
                onChange={(e) => setPage(parseInt(e.target.value, 10))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Conta Pagamento"
                value={paymentAccount}
                onChange={(e) => setPaymentAccount(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={consultarMovimentos}
                fullWidth
                disabled={loading}
                startIcon={loading && <CircularProgress size={20} />}
              >
                {loading ? 'Consultando...' : 'Consultar Extrato LQ'}
              </Button>
            </Grid>

            {movementsData && (
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table aria-label="movements table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Ref</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell>Data de Criação</TableCell>
                        <TableCell>Devedor</TableCell>
                        <TableCell>Credor</TableCell>
                        <TableCell>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {movementsData.data.transfers.map((transfer) => (
                        <TableRow key={transfer.id}>
                          <TableCell>{transfer.endToEnd}</TableCell>
                          <TableCell>{transfer.status}</TableCell>
                          <TableCell>{Formatar.formatarMoeda(transfer.amount)}</TableCell>
                          <TableCell>{Formatar.formatarDateAnoHoraSegundo(transfer.createdAt)}</TableCell>
                          <TableCell>{`${transfer.debtor.name} - ${transfer.debtor.document}`}</TableCell>
                          <TableCell>{`${transfer.creditor.name} - ${transfer.creditor.document}`}</TableCell>
                          <TableCell>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => handleOpenComprovante(transfer)}
                            >
                              Gerar Comprovante
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Modal para exibir o comprovante */}
      <Dialog open={Boolean(selectedComprovante)} onClose={handleCloseComprovante} maxWidth="sm" fullWidth>
        <DialogTitle>Comprovante de Movimentação</DialogTitle>
        <DialogContent>
          {selectedComprovante && <Comprovante dados={selectedComprovante} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseComprovante} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PainelTransferencia;
