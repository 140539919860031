import React, { useState, useEffect } from 'react';
import * as Funcoes from '../../functions/functions';
import * as Format from "../../functions/Format";
import {
    Box,
    Grid,
    Typography,
    Container
} from '@mui/material';

const Home = ({ user, tariff, checkAuthentication }) => {
    const [state, setState] = useState({
        user: JSON.parse(localStorage.getItem('user')),
        painelgeral: [],
        loading: true,
        totalContasFilhas: 0,
        balance: 0
    });

    useEffect(() => {
        async function fetchDatacheck() {
            await checkAuthentication();
        }
        fetchDatacheck();
    }, []);

    // useEffect separado para garantir que o `user` esteja carregado antes de prosseguir
    useEffect(() => {
        if (user && Object.keys(user).length > 0) { // Certifica-se que o user está disponível e carregado
            setState(prevState => ({
                ...prevState,
                user: user,
                painelgeral: user.configData?.keypix ? JSON.parse(user.configData.keypix) : []
            }));
            buscarContas();
        }
    }, [user]); // Executa sempre que `user` mudar



    const buscarContas = async () => {
        const managerId = user?.id;
        try {
            const data = await Funcoes.getAccountSummaryUser(managerId);
            //console.log('Resumo das contas:', data);

            setState(prevState => ({
                ...prevState,
                totalContasFilhas: data?.totalAccounts || 0,
                balance: data?.totalBalance || 0,
                loading: false // Marca o carregamento como concluído
            }));
        } catch (error) {
            //console.error('Erro ao obter resumo das contas:', error);
            setState(prevState => ({ ...prevState, loading: false })); // Marca o carregamento como concluído mesmo em caso de erro
        }
    };

    if (state.loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h4">Carregando...</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 14, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ flexGrow: 1, padding: 2 }}>
                        <h2>Painel Gerente {state.user?.userData?.name?.split(' ')[0]}</h2>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                gap: 2,
                                flexWrap: 'wrap'
                            }}
                        >
                            <Box
                                sx={{
                                    width: 250,
                                    height: 100,
                                    backgroundColor: 'white',
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    padding: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                                    GERENTE ID: {state.user?.id}
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: 1, fontSize: '1.2em' }}>
                                    SALDO {Format.converterCentavosParaReais(state.user?.configData?.balance)}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    width: 250,
                                    height: 100,
                                    backgroundColor: 'white',
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    padding: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                                    TOTAL CONTAS: {state.totalContasFilhas}
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: 1, fontSize: '1.2em' }}>
                                    SALDO {Format.converterCentavosParaReais(state.balance)}
                                </Typography>
                            </Box>
                        </Box>
                        <h2 className='mt-5'>Chave Pix Depósito</h2>

                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                gap: 2,
                                flexWrap: 'wrap'
                            }}
                        >
                            {state.painelgeral.map((item, index) => (
                                item.status == 1 && (
                                    <Box
                                        key={index}
                                        sx={{
                                            backgroundColor: 'white',
                                            borderRadius: 2,
                                            boxShadow: 3,
                                            padding: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {/* <QRCode value={item.qrcode.chave} size={200} /> */}
                                        <Typography variant="h6" sx={{ fontSize: '0.8em', marginTop: 1 }}>
                                            {item.keypix}
                                        </Typography>
                                    </Box>
                                )
                            ))}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Home;
