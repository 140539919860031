import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    Button,
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Snackbar,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    IconButton,
    InputAdornment
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { columns } from './utils/constants';
import * as Formatar from './utils/format';
import moment from 'moment';
import * as Funcoes from '../../functions/functions';
import Compressor from 'compressorjs';

import TarifaDialog from './utils/components/TarifaDialog';
import IsencaoDialog from './utils/components/IsencaoDialog';

function getNestedValue(obj, path) {
    return path.split('.').reduce((value, key) => (value ? value[key] : null), obj);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const calculateRemainingTime = (creationTime) => {
    const endTime = moment(creationTime).add(3, 'hours').add(40, 'minutes');

    const now = moment();
    const duration = moment.duration(endTime.diff(now));

    const totalSeconds = duration.asSeconds();

    if (totalSeconds <= 0) {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
            totalSeconds: 0,
        };
    }

    return {
        hours: Math.floor(duration.asHours()),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
        totalSeconds,
    };
};


const CountdownTimer = ({ id, creationTime, onTimeEnd, pixKeyId, onPixKeyDelete }) => {
    const [timeLeft, setTimeLeft] = useState(calculateRemainingTime(creationTime));
    const [hasEnded, setHasEnded] = useState(false);

    useEffect(() => {
        const timerId = setInterval(() => {
            const newTimeLeft = calculateRemainingTime(creationTime);

            if (newTimeLeft.totalSeconds <= 0 && !hasEnded) {
                clearInterval(timerId);
                setTimeLeft({
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                    totalSeconds: 0,
                });
                setHasEnded(true);
                if (onTimeEnd) onTimeEnd();
                if (onPixKeyDelete) onPixKeyDelete(pixKeyId, id, false); // Exclui a chave Pix
            } else {
                setTimeLeft(newTimeLeft);
            }
        }, 1000);

        return () => clearInterval(timerId);
    }, [creationTime, onTimeEnd, hasEnded, onPixKeyDelete, pixKeyId]);

    return <span>{`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}</span>;
};

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

function descendingComparator(a, b, orderBy) {
    const valueA = getNestedValue(a, orderBy);
    const valueB = getNestedValue(b, orderBy);
    if (valueB < valueA) {
        return -1;
    }
    if (valueB > valueA) {
        return 1;
    }
    return 0;
};

const ChaveTexto = {
    0: 'CPF',
    1: 'CNPJ',
    2: 'TELEFONE',
    3: 'EMAIL',
    4: 'EVP'
};

const DataTable = ({ count, state, order, orderBy, rowsPerPage, page, onSort, onChangePage, onChangeRowsPerPage, fetchcheckAuthentication, user, setState }) => {
    const [activatedKeys, setActivatedKeys] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [pixCode, setPixCode] = useState('');
    const [openExtratoDialog, setOpenExtratoDialog] = useState(false);
    const [selectedExtrato, setSelectedExtrato] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [openMasterDialog, setOpenMasterDialog] = useState(false);
    const [openIsencaoDialog, setOpenIsencaoDialog] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [tipoChavePix, setTipoChavePix] = useState('');
    const [chavePix, setChavePix] = useState('');
    const [manutencaoLoad, setManutencaoLoad] = useState(false);
    const [novaSenha, setNovaSenha] = useState('');
    const [novoEmail, setNovoEmail] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [selectedUserIsencao, setSelectedUserIsencao] = useState({});
    const [casadeaposta, setcasadeaposta] = useState('');
    const [login, setlogin] = useState('');
    const [password, setpassword] = useState('');
    const [motivo, setmotivo] = useState('');
    const [openTarifaDialog, setOpenTarifaDialog] = useState(false);
    const [dadosAcao, setDadosAcao] = useState([]);
    const [descricao, setDescricao] = useState('');
    const [tipo_de_cobranca, setTipoCobranca] = useState('');
    const [valor, setValor] = useState('');
    const [childId, setChildId] = useState('');
    const [kycConta, setKycConta] = useState({
        frontBase64: null,
        backBase64: null,
        face1Base64: null,
        face2Base64: null,
        docFaceBase64: null,
    });
    const [loadingKYC, setLoadingKYC] = useState(false);
    const [kycStatus, setKycStatus] = useState('');
    const [tariff, setTariff] = useState(JSON.parse(localStorage.getItem('tariff')));

    const handleActivate = async (id) => {
        try {
            const userConfig = state.tabelaFiltro.find(row => row.id == id)?.configData;
            const chaveCpfInativa = userConfig?.keypix && JSON.parse(userConfig.keypix).some(
                chave => chave.typekey == 0 && chave.status != 1
            );

            if (!chaveCpfInativa) {
                // Chave CPF está inativa, criar uma nova chave Pix CPF
                await handleCreatePixKey({ typekey: 0, userId: id }, true); // Passa true para fromActivation
            }

        } catch (error) {
            //console.error("Erro ao ativar a chave Pix:", error);
            setSnackbar({ open: true, message: 'Erro ao ativar a chave Pix.', severity: 'error' });
        }
    };

    const handleOpenDialog = (row) => {
        setChildId(row.id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setPixCode(''); // Limpar o campo ao fechar
    };

    const handleOpenMasterDialog = async (id, updateData = false) => {
        setLoading(true); // Inicia o loading
        try {
            const response = await Funcoes.getUserDetail(id);
            //console.log(response);
            setUserDetails(response);

            if (updateData) {
                // Verifica se 'data' é um array antes de tentar usar o .map()
                setState(prevState => {
                    const prevData = Array.isArray(prevState.tabelaFiltro) ? prevState.tabelaFiltro : [];

                    return {
                        ...prevState,
                        tabelaFiltro: prevData.map(item =>
                            item.id == id ? { ...item, ...response } : item
                        )
                    };
                });

            }

            else {
                setOpenMasterDialog(true);
            }
        } catch (error) {
            //console.error('Erro ao obter detalhes do usuário:', error);
            setSnackbar(prevState => ({ ...prevState, open: true, message: 'Erro ao carregar detalhes do usuário.', severity: 'error' }));
        } finally {
            setLoading(false); // Termina o loading
        }
    };

    const handleCloseMasterDialog = () => {
        setOpenMasterDialog(false);
        setKycStatus("");
        setLoadingKYC(false);
    };

    const handleCloseIsencaoDialog = () => {
        setOpenIsencaoDialog(false);
        setSelectedUserIsencao({});
        setcasadeaposta('');
        setlogin('');
        setpassword('');
        setmotivo('');
    };

    const handleOpenExtratoDialog = async (row) => {
        const accountData = row.accountData?.[0];
        const account = accountData?.id;

        if (!account) {
            //console.error('Conta não encontrada para o usuário:', row.userData.name);
            setSnackbar({ open: true, message: 'Conta não encontrada.', severity: 'error' });
            return;
        }

        //console.log('Buscando extrato para a conta:', account);
        setLoading(true);
        try {
            const fromDate = moment().subtract(30, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
            const toDate = moment().subtract('day').endOf('day').format('YYYY-MM-DD HH:mm:ss');


            //console.log('Extrato toDate:', toDate);

            const extrato = await Funcoes.fetchExtrato(account, fromDate, toDate, 0, 100);
            //console.log('Extrato encontrado:', extrato);
            setSelectedExtrato(extrato.data);
            setSnackbar({ open: true, message: 'Extrato carregado com sucesso!', severity: 'success' });
        } catch (error) {
            //console.error('Erro ao buscar extrato:', error);
            setSnackbar({ open: true, message: 'Erro ao buscar extrato.', severity: 'error' });
        } finally {
            setLoading(false);
        }
        setOpenExtratoDialog(true);
    };

    const handleCloseExtratoDialog = () => {
        setOpenExtratoDialog(false);
        setSelectedExtrato([]);
    };

    const handleSaque = async (row) => {
        setLoading(true);

        const accountData = row.accountData?.[0];
        const account = accountData?.userId;
        const value = row?.configData?.balance;

        if (!account || !value) {
            //console.error('Informações da conta ou valor inválidos.');
            setSnackbar({ open: true, message: 'Informações da conta ou valor inválidos.', severity: 'error' });
            setLoading(false);
            return;
        }

        try {
            const payload = {
                key: '1',
                typekey: 1,
                payerUserId: account,
                managerUserId: user.id,
                adminUserId: 1,
                value: value,
                tariffkey: 'tarifa_saque_filha',
                status: 1,
                description: 'Saque com tarifa',
                movementid: 101,
                liquidatorjson: { additionalInfo: 'Saque automático' },
                typemovement: 'saque'
            };
            //console.log(payload);
            const response = await Funcoes.createSplitExternalMovement(payload);
            //console.log('Saque realizado com sucesso:', response);

            if (response.sucesso) {
                //consultarMov({ id: response.data.endToEnd, desc: 'Saque realizado com sucesso!', user: account });

                await fetchcheckAuthentication();
                await handleOpenMasterDialog(account, true); // Recarrega as informações do usuário
                setSnackbar({ open: true, message: 'Saque realizado com sucesso!', severity: 'success' });
                setChildId('');
                setOpenDialog(false);
                setPixCode(''); // Limpar o campo ao fechar 
                setLoading(false); // Finaliza o estado de carregamento
            }



        } catch (error) {
            //console.error('Erro ao realizar o saque:', error);
            setSnackbar({ open: true, message: 'Erro ao realizar o saque.', severity: 'error' });
            setLoading(false);
        }
    };

    const handleCreatePixKey = async ({ typekey, userId }, fromActivation = false) => {
        setLoading(true);
        try {
            const payload = { userId, typekey };
            const response = await Funcoes.createKeyPix(payload);
            //console.log('Chave Pix criada com sucesso:', response);

            const TIPO_CHAVE_TEXTO = {
                0: 'CPF',
                1: 'CNPJ',
                2: 'PHONE',
                3: 'EMAIL',
                4: 'EVP'
            };

            setSnackbar({ open: true, message: `Chave Pix do tipo ${TIPO_CHAVE_TEXTO[typekey]} criada com sucesso!`, severity: 'success' });

            if (fromActivation) {
                // Agora ativa o timer após a atualização dos dados e a criação da chave Pix
                const newTime = moment().toISOString();
                setTimeout(() => {
                    setActivatedKeys((prev) => ({ ...prev, [userId]: newTime }));
                }, 4000);

            }

            await handleOpenMasterDialog(userId, true); // Recarrega as informações do usuário
        } catch (error) {
            //console.error('Erro ao criar chave Pix:', error);
            setSnackbar({ open: true, message: 'Erro ao criar chave Pix.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleDeletePixKey = async (chavePix, id, mostrar = true) => {
        setLoading(true);
        try {
            const response = await Funcoes.deleteKeyPix(chavePix);

            if (mostrar) { setSnackbar({ open: true, message: `Chave Pix ${chavePix} deletada com sucesso!`, severity: 'success' }); }

            // Aguarda a exclusão ser concluída antes de atualizar o usuário
            await handleOpenMasterDialog(id, true);

            // Atualiza o estado `activatedKeys` para reativar o botão "Ativar"
            setActivatedKeys(prevKeys => {
                const updatedKeys = { ...prevKeys };
                delete updatedKeys[id]; // Remove o ID do usuário das chaves ativas
                return updatedKeys;
            });

        } catch (error) {
            setSnackbar({ open: true, message: 'Erro ao deletar chave Pix.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    const handleSelectKey = (event) => {
        setTipoChavePix(event.target.value);
    };

    const handleInputChange = (event) => {
        setChavePix(event.target.value);
    };


    const handleInputChangeEmail = (event) => {
        setNovoEmail(event.target.value);
    };

    const criarChavePixPersonalizada = async () => {
        // Verificação dos campos obrigatórios
        if (tipoChavePix === '' || chavePix.trim() === '') {
            setSnackbar({ open: true, message: 'Por favor, preencha todos os campos.', severity: 'warning' });
            return;
        }

        setManutencaoLoad(true);
        try {
            await Funcoes.createKeyPixDirect({
                tipoChave: tipoChavePix,
                chave: chavePix,
                userId: userDetails.id
            });
            setSnackbar({ open: true, message: 'Chave Pix criada com sucesso!', severity: 'success' });
            await handleOpenMasterDialog(userDetails.id); // Recarrega as informações do usuário

            // Limpar as variáveis após o sucesso
            setTipoChavePix('');
            setChavePix('');
        } catch (error) {
            //console.error('Erro ao criar chave Pix:', error);
            setSnackbar({ open: true, message: 'Erro ao criar chave Pix.', severity: 'error' });
        } finally {
            setManutencaoLoad(false);
        }
    };

    const handleToggleAccountStatus = async (account) => {
        const newStatus = account.status == 1 ? 2 : 1;

        try {
            // Chame a função de atualização de conta com o novo status
            await Funcoes.updateAccountStatus(account.userId, newStatus);
            setSnackbar({ open: true, message: `Conta ${newStatus == 1 ? 'Ativada' : 'Bloqueada'} com sucesso!`, severity: 'success' });

            // Recarregar as informações do usuário para refletir o novo status
            await handleOpenMasterDialog(account.userId);
        } catch (error) {
            //console.error('Erro ao alternar o status da conta:', error);
            setSnackbar({ open: true, message: 'Erro ao alterar o status da conta.', severity: 'error' });
        }
    };

    const handleToggleCanTransfer = async (account) => {
        //console.log(account);
        const newStatus = account.cantransfer == 1 ? 0 : 1;

        try {
            // Chame a função de atualização de conta com o novo status
            await Funcoes.updateUserCanTransfer(account.userId);
            setSnackbar({ open: true, message: `Pagar QRCode ${newStatus == 1 ? 'desbloqueado' : 'bloqueado'} com sucesso!`, severity: 'success' });

            // Recarregar as informações do usuário para refletir o novo status
            await handleOpenMasterDialog(account.userId, true);
        } catch (error) {
            //console.error('Erro ao alternar o status da conta:', error);
            setSnackbar({ open: true, message: 'Erro ao alterar o status do pagar qrcode.', severity: 'error' });
        }
    };

    const handleUpdateBalance = async ({ account, userId }) => {
        try {
            // Chama a função de atualização de saldo com os detalhes da conta
            await Funcoes.updateBalanceAccount(account);
            setSnackbar({ open: true, message: 'Saldo atualizado com sucesso!', severity: 'success' });

            // Recarrega as informações do usuário para refletir o novo saldo
            await handleOpenMasterDialog(userId);
        } catch (error) {
            //console.error('Erro ao atualizar saldo da conta:', error);
            setSnackbar({ open: true, message: 'Erro ao atualizar o saldo da conta.', severity: 'error' });
        }
    };


    const handleGerarOperator = async (name, account, XAccountId, id) => {
        try {
            // Chama a função de criação da conta operador com os detalhes fornecidos
            await Funcoes.GerarOperator(name, account, XAccountId);
            setSnackbar({ open: true, message: 'Conta Operador criada com sucesso!', severity: 'success' });

            // Recarrega as informações do usuário para refletir a nova conta
            await handleOpenMasterDialog(id);
        } catch (error) {
            //console.error('Erro ao criar conta operador:', error);
            setSnackbar({ open: true, message: 'Erro ao criar conta operador.', severity: 'error' });
        }
    };

    const formatTelefone = (telefone) => {
        if (!telefone) return '';
        return telefone.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
    };

    const formatDocumento = (documento) => {
        if (!documento) return '';
        if (documento.length === 11) {
            // CPF
            return documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (documento.length === 14) {
            // CNPJ
            return documento.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
        return documento;
    };

    const formatDataNascimento = (data) => {
        if (!data) return '';
        const date = new Date(data);
        return date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
    };

    const formatSexo = (sexo) => {
        if (!sexo) return '';
        return sexo === 'M' ? 'Masculino' : 'Feminino';
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        // Verifica se o valor tem apenas números e se tem no máximo 6 caracteres
        if (/^\d{0,6}$/.test(value)) {
            setNovaSenha(value);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleUpdatePassword = async (userId, password) => {
        setLoading(true);
        try {
            const response = await Funcoes.updateUserPassword(userId, password);
            setSnackbar({ open: true, message: 'Senha atualizada com sucesso!', severity: 'success' });
            setNovaSenha('');  // Limpa o campo após a atualização
        } catch (error) {
            //console.error('Erro ao atualizar a senha:', error);
            setSnackbar({ open: true, message: 'Erro ao atualizar a senha.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleUpdateEmail = async (userId, email) => {
        if (!validateEmail(email)) {
            setSnackbar({ open: true, message: 'Por favor, insira um e-mail válido.', severity: 'warning' });
            return;
        }

        setLoading(true);
        try {
            const response = await Funcoes.updateUserEmail(userId, email);
            setSnackbar({ open: true, message: 'E-mail atualizado com sucesso!', severity: 'success' });
            setNovoEmail('');  // Limpa o campo após a atualização
            await handleOpenMasterDialog(userId);
        } catch (error) {
            setSnackbar({ open: true, message: 'Erro ao atualizar o e-mail.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleSolicitarIsencaoDialog = async () => {
        // Verificando se todos os campos estão preenchidos
        if (!casadeaposta || !login || !password || !motivo) {
            // Atualizar o estado da Snackbar para mostrar a mensagem de erro
            setSnackbar({
                open: true,
                message: 'Por favor, preencha todos os campos obrigatórios.',
                severity: 'error' // Define a severidade como erro
            });
            return; // Para a execução se os campos não estiverem preenchidos
        }


        try {
            const payload = {
                bettinghouse: casadeaposta,
                login: login,
                password: password,
                reason: motivo,
                value: 0,
                originaccount: selectedUserIsencao.id,
                status: 1,
                managerId: user.id,
                tariffkey: "tarifa_criar_pedido_isencao",
                demand: user.accountData[0]?.profile === 'master' ? 0 : 1,

            };
            //console.log(payload);
            const response = await Funcoes.createExemptionAccount(payload);
            //console.log('Isenção criada com sucesso:', response);
            // Se todos os campos estiverem preenchidos, mostrar mensagem de sucesso
            handleCloseIsencaoDialog()
            setSnackbar({
                open: true,
                message: 'Isenção criada com sucesso',
                severity: 'success' // Define a severidade como sucesso
            });

        } catch (error) {
            //console.error('Erro ao solicitar a isenção:', error);
            setSnackbar({ open: true, message: error.message, severity: 'error' });
        }


    };

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const regex = /^[0-9.,]*$/; // Permitir apenas números, pontos e vírgulas

        if (regex.test(inputValue)) {
            setValor(inputValue);
        }
    };

    const fecharModalTariaPadrao = () => {
        setOpenTarifaDialog(false);
    };

    const alterarTarifa = async () => {
        // Ativa o estado de carregamento
        setOpenTarifaDialog(true);
        const originaccount = userDetails.id;
        try {
            // Faz a chamada para a função de alteração da tarifa, passando os dados dos states
            const response = await Funcoes.alterarTarifaPadrao(
                dadosAcao.tariffkey,  // Pegando o chaveTarifa de dadosAcao
                descricao,
                valor,
                tipo_de_cobranca,
                dadosAcao,
                originaccount

            );

            //console.log(response);
            //console.log('Tarifa alterada com sucesso', 'success');

            // Desativa o estado de carregamento e fecha o diálogo
            setOpenTarifaDialog(false);
            setDadosAcao([]);
            setDescricao('');
            setTipoCobranca('');
            setValor('');

            setTimeout(async () => {
                await handleOpenMasterDialog(userDetails.id, true); // Recarrega as informações do usuário
            }, 2000);

        } catch (error) {
            //console.error('Erro na alteração da tarifa', error);

            // Desativa o estado de carregamento e fecha o diálogo em caso de erro
            setOpenTarifaDialog(false);
        }
    };

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.3, // Reduzir a qualidade ainda mais
                width: file.width * 0.8, // Reduzir a largura em 80% do original
                height: file.height * 0.8, // Reduzir a altura em 80% do original
                success(result) {
                    resolve(result);
                },
                error(err) {
                    reject(err);
                },
            });
        });
    };


    const handleImageUpload = async (event, imageType) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const compressedFile = await compressImage(file);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setKycConta(prevState => ({
                        ...prevState,
                        [`${imageType}Base64`]: reader.result.split(',')[1] // Remove o prefixo da base64
                    }));
                };
                reader.readAsDataURL(compressedFile); // Converte o arquivo comprimido para base64
            } catch (error) {
                //console.error('Erro ao comprimir a imagem:', error);
            }
        }
    };

    const handleSendImageUpload = async (Conta) => {
        setLoading(true);

        // Verifica se cada imagem foi carregada e exibe mensagens específicas
        if (!kycConta.frontBase64) {
            setSnackbar({ open: true, message: 'Por favor, carregue a imagem da frente do documento.', severity: 'warning' });
            setLoading(false);
            return;
        }
        if (!kycConta.backBase64) {
            setSnackbar({ open: true, message: 'Por favor, carregue a imagem do verso do documento.', severity: 'warning' });
            setLoading(false);
            return;
        }
        if (!kycConta.face1Base64) {
            setSnackbar({ open: true, message: 'Por favor, carregue a foto frontal da pessoa.', severity: 'warning' });
            setLoading(false);
            return;
        }
        if (!kycConta.face2Base64) {
            setSnackbar({ open: true, message: 'Por favor, carregue a foto lateral da pessoa.', severity: 'warning' });
            setLoading(false);
            return;
        }
        if (!kycConta.docFaceBase64) {
            setSnackbar({ open: true, message: 'Por favor, carregue a imagem do documento com a foto da pessoa.', severity: 'warning' });
            setLoading(false);
            return;
        }

        try {
            const response = await Funcoes.enviarKycDoc(Conta, kycConta);
            setSnackbar({ open: true, message: 'KYC enviado com sucesso!', severity: 'success' });
        } catch (error) {
            //console.error('Erro ao enviar o KYC:', error);
            setSnackbar({ open: true, message: error.message || 'Erro ao enviar o KYC.', severity: 'error' });
        } finally {
            setLoading(false); // Finaliza o indicador de carregamento
        }
    };

    // Função para validar o transactionId
    const isValidTransactionId = (transactionId, initiationType) => {
        const regex = /^[A-Za-z0-9]+$/; // Verifica se contém apenas letras e números
        const length = transactionId.length;

        if (!regex.test(transactionId)) {
            return false; // Se contiver caracteres inválidos, retorna falso
        }

        if (initiationType === 'QRES' && length <= 25) {
            return true; // Valida para QRES (até 25 caracteres)
        }

        if (initiationType === 'QRDN' && length >= 26 && length <= 35) {
            return true; // Valida para QRDN (entre 26 e 35 caracteres)
        }

        return false; // Se não atender às condições, retorna falso
    };

    // Função para gerar um transactionId com base no tipo de Pix
    const generateTransactionId = (initiationType) => {
        let transactionId = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = initiationType === 'QRES' ? 25 : getRandomInt(26, 35); // QRES até 25 caracteres, QRDN entre 26-35

        for (let i = 0; i < length; i++) {
            transactionId += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return transactionId;
    };

    // Função auxiliar para gerar um número aleatório em um intervalo
    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const handlePay = async ({ managerId, childId, pixCopiaColaData }) => {

        //console.log('Pagando com o código Pix:', pixCopiaColaData);
        if (!pixCopiaColaData) {
            setSnackbar({ open: true, message: 'Pix "Copia e Cola" não fornecido', severity: 'warning' });
            return;
        }

        setLoading(true); // Inicia o estado de carregamento

        try {
            // Primeira consulta: busca os detalhes do Pix "Copia e Cola"
            const pixCopiaColaResponse = await Funcoes.consultarPix({
                userId: childId, // ID da conta gerente
                chavePix: pixCopiaColaData, // O dado do Pix "Copia e Cola" passado
                typekey: 2, // Typekey 2 para "Copia e Cola"
            });

            const pixDetails = pixCopiaColaResponse.data;
            const { transactionIdentification, amount, key } = pixDetails; // 'key' será usada para a segunda consulta

            if (!key) {
                setSnackbar({ open: true, message: 'Erro ao consultar Pix "Copia e Cola", chave Pix não encontrada', severity: 'error' });
                setLoading(false);
                return;
            }

            // Segunda consulta: busca os detalhes do credor usando a chave Pix do retorno
            const pixKeyDetailResponse = await Funcoes.consultarPix({
                userId: childId, // ID da conta gerente
                chavePix: key, // Chave Pix retornada do "Copia e Cola"
                typekey: 1, // Typekey 1 para buscar detalhes da chave Pix
            });

            const pixKeyDetails = pixKeyDetailResponse.data;
            const { ownerName, ownerDocument, ispb, accountBranch, accountNumber, accountType } = pixKeyDetails;

            // Verificação de dados obrigatórios
            if (!ownerName || !ownerDocument || !ispb || !accountBranch || !accountNumber || !accountType) {
                setSnackbar({ open: true, message: 'Dados do credor incompletos. Não foi possível realizar o pagamento.', severity: 'error' });
                setLoading(false);
                return;
            }

            // Monta os dados do credor com base no retorno da segunda consulta (chave Pix)
            const creditorDetails = {
                name: ownerName, // Nome do destinatário do Pix
                document: ownerDocument, // Documento (CPF ou CNPJ) do destinatário
                account: {
                    ispb, // ISPB do banco
                    branch: accountBranch,   // Agência do destinatário
                    number: accountNumber, // Conta do destinatário
                    type: accountType      // Tipo de conta (CACC - Conta Corrente)
                }
            };

            // Verifica o tipo de QR Code (estático ou dinâmico)
            const initiationType = pixDetails.type === "STATIC" ? "QRES" : "QRDN";

            // Gera o transactionId com base no tipo de iniciação
            const transactionId = isValidTransactionId(transactionIdentification, initiationType)
                ? transactionIdentification
                : generateTransactionId(initiationType);

            // Realiza a transferência da conta gerente para a conta filha e paga o Pix "Copia e Cola"
            const result = await Funcoes.realizarTransferenciaComPix({
                managerId,  // ID da conta gerente
                childId,    // ID da conta filha
                pixKey: {
                    id: pixDetails.key, // EMV é o código Pix "Copia e Cola"
                    endToEndId: transactionId, // Identificação da transação
                    ownerName: creditorDetails.name,       // Nome do destinatário
                    ownerDocument: creditorDetails.document, // Documento do destinatário
                    ispb: creditorDetails.account.ispb,    // ISPB do banco
                    accountBranch: creditorDetails.account.branch, // Agência
                    accountNumber: creditorDetails.account.number, // Conta
                    accountType: creditorDetails.account.type // Tipo de conta (CACC)
                },
                pixAmount: amount,  // Valor do Pix a ser pago
                creditor: creditorDetails, // Informações do credor para a transferência
                initiationType
            });
            //console.log('Transferência e Pix realizados com sucesso:', JSON.stringify(result));
            if (result.data.data.endToEnd) {
                // consultarMov({ id: result.data.endToEnd, desc: 'Transferência realizada com sucesso!', user: childId });
                await fetchcheckAuthentication();
                await handleOpenMasterDialog(childId, true); // Recarrega as informações do usuário
                setSnackbar({ open: true, message: 'Transferência realizada com sucesso!', severity: 'success' });
                setChildId('');
                setOpenDialog(false);
                setPixCode(''); // Limpar o campo ao fechar 
                setLoading(false); // Finaliza o estado de carregamento
            } else {
                await fetchcheckAuthentication();
                await handleOpenMasterDialog(childId, true); // Recarrega as informações do usuário
                setSnackbar({ open: true, message: 'Erro ao realizar Transferência Pix', severity: 'error' });
                setLoading(false);
            }

        } catch (error) {
            await fetchcheckAuthentication();
            await handleOpenMasterDialog(childId, true); // Recarrega as informações do usuário
            //console.error('Erro ao realizar transferência e Pix:', error);
            setSnackbar({ open: true, message: 'Erro ao realizar Transferência Pix', severity: 'error' });
            setLoading(false);
        }

    };

    const consultarMov = async ({ id, desc, user }) => {

        const result = await Funcoes.consultarMovimentacao(id);
        //console.log(result);

        if (result == 0 || result?.status != "COMPLETED") {
            setTimeout(() => {
                consultarMov({ id, desc, user });
            }, 3000);

        } else {
            await fetchcheckAuthentication();
            await handleOpenMasterDialog(user, true); // Recarrega as informações do usuário
            setSnackbar({ open: true, message: desc, severity: 'success' });
            setChildId('');
            setOpenDialog(false);
            setPixCode(''); // Limpar o campo ao fechar 
            setLoading(false); // Finaliza o estado de carregamento
        }

    };

    // Função para verificar o status do KYC
    const verificarStatusKYC = async (accountId) => {
        setLoadingKYC(true);
        try {
            const response = await Funcoes.verificarStatusKYC(accountId);
            setKycStatus(response.data.kyc); // Atualiza o status do KYC
        } catch (error) {
            //console.error('Erro ao verificar o status do KYC:', error);
            setKycStatus('Erro ao verificar o status');
        } finally {
            setLoadingKYC(false);
        }
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align="left"
                                    sortDirection={orderBy === column.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => onSort(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell align="center">Ação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(state.tabelaFiltro, getComparator(order, orderBy))

                            .map((row, index) => {
                                const areButtonsDisabled = (row) => row.accountData[0]?.status === '2';
                                return (
                                    <TableRow
                                        key={row.id}
                                        className={index % 2 === 0 ? 'even-row' : 'odd-row'}
                                        sx={{
                                            backgroundColor: row.configData?.keypix && JSON.parse(row.configData.keypix).some(chave => chave.typekey == 0 && chave.status == 1)
                                                ? 'lightblue' // Cor azul claro quando o Pix estiver ativo
                                                : 'inherit', // Mantém a cor padrão caso contrário
                                        }}
                                    >
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align="left">
                                                {column.id === 'id' && row.id}
                                                {column.id === 'userData.name' && row.userData.name.toUpperCase()}
                                                {column.id === 'userData.document' && Formatar.cpf_mask(row.userData.document)}
                                                {column.id === 'email' && row.email}
                                                {column.id === 'userData.telephone' && Formatar.cel_mask(row.userData.telephone)}
                                                {column.id === 'configData.balance' && Formatar.converterCentavosParaReais(row?.configData?.balance)}
                                                {column.id === 'keyPix' && (
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        {row.configData?.keypix ? (
                                                            JSON.parse(row.configData.keypix).some(chave => chave.typekey == 0) ? (
                                                                JSON.parse(row.configData.keypix).map((chave, index) => {
                                                                    if (chave.typekey == 0) { // Verifica se é a chave do tipo CPF (typekey === 0)
                                                                        //console.log(chave);
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <Typography variant="h6" sx={{ fontSize: '0.8em' }}>
                                                                                    {chave.status == 1 ? "ATIVO" : "INATIVO"}
                                                                                </Typography>
                                                                                {chave.status == 1 && chave.keypix.length == 11 && (
                                                                                    /*  <CountdownTimer
                                                                                         creationTime={chave.createdAt}
                                                                                         onTimeEnd={async () => {
                                                                                             //console.log(chave)
                                                                                             await handleOpenMasterDialog(row.configData.userId, true);
                                                                                         }}
                                                                                     /> */
                                                                                    <CountdownTimer
                                                                                        key={row.configData.userId}
                                                                                        id={row.configData.userId}
                                                                                        creationTime={chave.createdAt}
                                                                                        onTimeEnd={async () => console.log('chave deletada')}
                                                                                        pixKeyId={chave.keypix} // ID da chave Pix para exclusão
                                                                                        onPixKeyDelete={handleDeletePixKey} // Função para excluir
                                                                                    />
                                                                                )}
                                                                            </React.Fragment>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })
                                                            ) : (
                                                                <Typography variant="h6" sx={{ fontSize: '0.8em' }}>INATIVO</Typography>
                                                            )
                                                        ) : (
                                                            <Typography variant="h6" sx={{ fontSize: '0.8em' }}>INATIVO</Typography>
                                                        )}
                                                    </Box>
                                                )}
                                            </TableCell>
                                        ))}

                                        <TableCell align="center" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                            {/* Verifica se os botões devem ser desabilitados com base no status */}
                                            {['Ativar', 'Pagar QRCode', 'Extrato', 'Saque', 'Isenção'].map((buttonLabel, index) => (
                                                <Button
                                                    key={index}
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        borderRadius: 2,
                                                        boxShadow: 3,
                                                        padding: 1,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                    }}
                                                    disabled={
                                                        !!areButtonsDisabled(row) ||
                                                        (buttonLabel === 'Ativar' && (
                                                            !!activatedKeys[row.id] ||
                                                            !!(row.configData?.keypix && JSON.parse(row.configData.keypix).some(
                                                                chave => chave.typekey == 0 && chave.status == 1 && chave.keypix.length == 11
                                                            ))
                                                        )) ||
                                                        (buttonLabel === 'Pagar QRCode' && (
                                                            !!(row.configData?.cantransfer === 0)
                                                        )) ||
                                                        (buttonLabel === 'Saque' && !!loading)
                                                    }

                                                    onClick={() => {
                                                        switch (buttonLabel) {
                                                            case 'Ativar':
                                                                handleActivate(row.id);
                                                                break;
                                                            case 'Pagar QRCode':
                                                                handleOpenDialog(row);
                                                                break;
                                                            case 'Extrato':
                                                                handleOpenExtratoDialog(row);
                                                                break;
                                                            case 'Saque':
                                                                handleSaque(row);
                                                                break;
                                                            case 'Isenção':
                                                                setSelectedUserIsencao(row);
                                                                setTimeout(() => {
                                                                    setOpenIsencaoDialog(true);
                                                                }, 1000);
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                >
                                                    {buttonLabel}
                                                </Button>
                                            ))}

                                            {/* Botão "Ação Master", sempre habilitado se o perfil for "master" */}
                                            {user.accountData[0]?.profile === 'master' && (
                                                <Button
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        borderRadius: 2,
                                                        boxShadow: 3,
                                                        padding: 1,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                    }}
                                                    onClick={() => handleOpenMasterDialog(row.id)}
                                                >
                                                    Ação Master
                                                </Button>
                                            )}
                                        </TableCell>

                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>

                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={count}  // O total de registros vem do servidor
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </TableContainer>

            {/* Dialog para Inserir código Pix */}
            <Dialog open={openDialog} onClose={handleCloseDialog} sx={{ '& .MuiDialog-paper': { width: '600px', maxWidth: '600px' } }}>
                <DialogTitle>Inserir código Pix</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="pixCode"
                        label="Pix copia e cola"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={pixCode}
                        onChange={(e) => setPixCode(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={handleCloseDialog} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={() => handlePay({ managerId: user.id, childId: childId, pixCopiaColaData: pixCode })} color="primary" variant="contained" disabled={loading} // Desabilita o botão enquanto o loading está ativo
                        >
                            {loading ? (
                                <CircularProgress size={24} color="inherit" /> // Mostra o loading
                            ) : (
                                'Pagar' // Texto padrão do botão
                            )}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            {/* Dialog para visualizar o Extrato */}
            <Dialog open={openExtratoDialog} onClose={handleCloseExtratoDialog} maxWidth="lg" fullWidth>
                <DialogTitle>Extrato de Transações</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Typography>Carregando extrato...</Typography>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="extrato table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Descrição</TableCell>
                                        <TableCell align="center">Valor R$</TableCell>
                                        <TableCell align="center">Data/Hora</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedExtrato.map((item, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                backgroundColor: item.value > 0 ? 'lightgreen' : 'lightcoral',
                                            }}
                                        >
                                            <TableCell align="center">{item.description}</TableCell>
                                            <TableCell align="center">{Formatar.converterCentavosParaReais(item.value)}</TableCell>
                                            <TableCell align="center">{Formatar.formatarDateAnoHoraSegundo(item.createdAt)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExtratoDialog}>Fechar</Button>
                </DialogActions>
            </Dialog>

            {/* Novo Dialog para perfis "master" */}
            <Dialog open={openMasterDialog} onClose={handleCloseMasterDialog} maxWidth="xl" fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        {/* Texto "Ação Master" alinhado à esquerda */}
                        <Typography variant="h6">Ação Master</Typography>

                        {/* Texto "Banco LQ: {userDetails.chosenbank}" alinhado à direita */}
                       {/*  <Typography variant="h6">Banco LQ: {userDetails?.chosenbank.toUpperCase()}</Typography> */}
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {userDetails ? (
                        <>
                            <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                                {/* Caixa 1: Status da Conta e Botão */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ padding: 2 }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 2 }}>
                                            Status da Conta: {userDetails.accountData[0]?.status == 1 ? "Ativa" : "Bloqueada"}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color={userDetails.accountData[0]?.status == 1 ? "secondary" : "primary"}
                                            onClick={() => handleToggleAccountStatus(userDetails.accountData[0])}
                                            sx={{ minWidth: '140px' }}
                                        >
                                            {userDetails.accountData[0]?.status == 1 ? "Bloquear Conta" : "Ativar Conta"}
                                        </Button>
                                    </Box>
                                </Grid>

                                {/* Caixa 2: Status Pagar QRCode e Botão */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ padding: 2 }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 2 }}>
                                            Status pagar QRCode: {userDetails.configData?.cantransfer == 1 ? "Desbloqueado" : "Bloqueado"}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color={userDetails.configData?.cantransfer == 1 ? "secondary" : "primary"}
                                            onClick={() => handleToggleCanTransfer(userDetails.configData)}
                                            sx={{ minWidth: '140px' }}
                                        >
                                            {userDetails.configData?.cantransfer == 1 ? "Bloquear QRCode" : "Desbloquear QRCode"}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>



                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Informações Básicas</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Nome:</Typography>
                                                <Typography>{userDetails.userData?.name}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Email:</Typography>
                                                <Typography>{userDetails.email}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Telefone:</Typography>
                                                <Typography>{formatTelefone(userDetails.userData?.telephone)}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Documento:</Typography>
                                                <Typography>{formatDocumento(userDetails.userData?.document)}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Data de Nascimento:</Typography>
                                                <Typography>{formatDataNascimento(userDetails.userData?.birth)}</Typography>
                                            </Paper>
                                        </Grid>
                                        {/*   <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Sexo:</Typography>
                                                <Typography>{formatSexo(userDetails.userData?.sex)}</Typography>
                                            </Paper>
                                        </Grid> */}
                                        {/*   <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Estado Civil:</Typography>
                                                <Typography>{userDetails.userData?.maritalstatus}</Typography>
                                            </Paper>
                                        </Grid> */}
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Saldo:</Typography>
                                                    <Typography>{Formatar.converterCentavosParaReais(userDetails.configData?.balance)}</Typography>
                                                </Box>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUpdateBalance({ account: userDetails.accountData[0].paymentaccount, userId: userDetails.accountData[0].userId })}
                                                >
                                                    Atualizar Saldo
                                                </Button>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>


                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dados da Conta</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {userDetails.accountData.map((account, index) => (
                                            <React.Fragment key={index}>
                                                <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Banco:</Typography>
                                                        <Typography>{account.bank}</Typography>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Agência:</Typography>
                                                        <Typography>{`${account.agency} - Dígito: ${account.digitagency}`}</Typography>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Conta:</Typography>

                                                        {
                                                            account.digitaccount == 0 ? (
                                                                <Typography>{`${account.account}`}</Typography>
                                                            ) : (
                                                                <Typography>{`${account.account} - Dígito: ${account.digitaccount}`}</Typography>
                                                            )
                                                        }
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Box>
                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Conta Pagamento:</Typography>
                                                            <Typography>{account.paymentaccount}</Typography>
                                                        </Box>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleGerarOperator(userDetails.userData?.name, account.account, account.paymentaccount, userDetails.id)}
                                                        >
                                                            Gerar Conta Operator
                                                        </Button>
                                                    </Paper>
                                                </Grid>
                                              {/*   <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Conta Pagamento:</Typography>
                                                        <Typography>{account.paymentaccount}</Typography>
                                                    </Paper>
                                                </Grid> */}
                                                {/*   <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Tipo de Conta:</Typography>
                                                        <Typography>{account.accounttype}</Typography>
                                                    </Paper>
                                                </Grid> */}
                                                {/*   <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Modelo de Conta:</Typography>
                                                        <Typography>{account.accountmodel}</Typography>
                                                    </Paper>
                                                </Grid> */}
                                                <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Status:</Typography>
                                                        <Typography>{account.status == 1 ? "ATIVA" : "BLOQUEADA"}</Typography>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Perfil:</Typography>
                                                        <Typography>{account.profile}</Typography>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Gerente ID:</Typography>
                                                        <Typography>{account.managerId}</Typography>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Nome do Gerente:</Typography>
                                                        <Typography>{account.managerName}</Typography>
                                                    </Paper>
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>


                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Configurações da Conta</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* Exibir as Chaves Pix */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6" sx={{ mt: 2 }}>Chaves Pix:</Typography>

                                        <Grid container spacing={2} sx={{ mt: 1 }}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="h6">Chaves Ativas (Liquidante)</Typography>
                                                {userDetails.configData?.keypix && JSON.parse(userDetails.configData.keypix)
                                                    .filter(chave => chave.status == 1)
                                                    .map((chave, index) => (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                p: 1,
                                                                border: '1px solid #ccc',
                                                                borderRadius: 1,
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <Box>
                                                                <Typography variant="body1">
                                                                    {`Tipo: ${ChaveTexto[chave.typekey]}`} {/* Exibe o nome do tipo de chave */}
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {`Chave: ${chave.keypix}`}
                                                                </Typography>
                                                            </Box>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => handleDeletePixKey(chave.keypix, userDetails.id)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                Deletar
                                                            </Button>
                                                        </Box>
                                                    ))}
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="h6">Chaves de Saída (Gerente)</Typography>
                                                {userDetails.configData?.keypix && JSON.parse(userDetails.configData.keypix)
                                                    .filter(chave => chave.status == 2)
                                                    .map((chave, index) => (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                p: 1,
                                                                border: '1px solid #ccc',
                                                                borderRadius: 1,
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <Box>
                                                                <Typography variant="body1">
                                                                    {"Chave Pix de Saída"}
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {`Tipo: ${ChaveTexto[chave.typekey]}`} {/* Exibe o nome do tipo de chave */}
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {`Chave: ${chave.keypix}`}
                                                                </Typography>
                                                            </Box>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => handleDeletePixKey(chave.keypix, userDetails.id)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                Deletar
                                                            </Button>
                                                        </Box>
                                                    ))}
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* Botões para criar novas chaves Pix */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6" sx={{ mt: 3 }}>Criar Chave Pix da Conta:</Typography>
                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                           {/*  <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleCreatePixKey({ typekey: 2, userId: userDetails.id })}
                                                >
                                                    Criar Chave Pix por Telefone
                                                </Button>
                                            </Grid> */}
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleCreatePixKey({ typekey: 0, userId: userDetails.id })}
                                                >
                                                    Criar Chave Pix por CPF/CNPJ
                                                </Button>
                                            </Grid>
                                         {/*    <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleCreatePixKey({ typekey: 3, userId: userDetails.id })}
                                                >
                                                    Criar Chave Pix por E-mail
                                                </Button>
                                            </Grid> */}
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleCreatePixKey({ typekey: 4, userId: userDetails.id })}
                                                >
                                                    Criar Chave Pix aleatória
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* Novo Box para criar a chave Pix personalizada */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6">{"Criar chave Pix de Saida: "}</Typography>
                                        <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                                            <InputLabel id="select-tipo-chave-label">Tipo de chave</InputLabel>
                                            <Select
                                                labelId="select-tipo-chave-label"
                                                id="select-tipo-chave"
                                                value={tipoChavePix}
                                                onChange={handleSelectKey}
                                                label="Tipo de chave"
                                            >
                                                <MenuItem value="">
                                                    <em>Escolha</em>
                                                </MenuItem>
                                                <MenuItem value={0}>CPF</MenuItem>
                                                <MenuItem value={1}>CNPJ</MenuItem>
                                                <MenuItem value={2}>TELEFONE</MenuItem>
                                                <MenuItem value={3}>E-MAIL</MenuItem>
                                                <MenuItem value={4}>ALEATÓRIA</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            label="Chave Pix"
                                            value={chavePix}
                                            onChange={handleInputChange}
                                            sx={{ mt: 2 }}
                                        />

                                        {manutencaoLoad ? (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={criarChavePixPersonalizada}
                                                sx={{ mt: 2 }}
                                            >
                                                Criar Chave Pix
                                            </Button>
                                        )}
                                    </Box>

                                    {/* Novo Box para alterar a senha */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6">Alterar Senha:</Typography>
                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    label="Nova Senha"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={novaSenha}
                                                    onChange={handlePasswordChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={togglePasswordVisibility}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    inputProps={{
                                                        maxLength: 6, // Limita o input para 6 caracteres
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUpdatePassword(userDetails.id, novaSenha)}
                                                    disabled={loading || novaSenha.length !== 6} // Desabilita o botão se a senha não tiver 6 caracteres
                                                >
                                                    {loading ? <CircularProgress size={24} /> : 'Atualizar Senha'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>


                                    {/* Novo Box para alterar o email */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6">Atualizar E-mail:</Typography>
                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    label="E-mail"
                                                    value={novoEmail}
                                                    onChange={handleInputChangeEmail}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUpdateEmail(userDetails.id, novoEmail)}
                                                    disabled={loading}
                                                >
                                                    {loading ? <CircularProgress size={24} /> : 'Atualizar E-mail'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* Novo Box para alterar a tarifas */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6">Tarifas:</Typography>
                                        <Grid container spacing={2}>
                                            {userDetails.tariffs.map((tariff, index) => (

                                                <Grid key={index} item xs={12} sm={6}>
                                                    <Paper elevation={3} sx={{ p: 2, borderRadius: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {`${tariff.description}`}
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {tariff.typeofcharge == 1 ? "Valor: " + Formatar.converterCentavosParaReais(tariff?.value ? tariff.value : 0) : "Valor: " + Formatar.converterCentavosEmPorcentagem(tariff?.value ? tariff.value : 0) + '%'}
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {`Tipo de cobrança: ${tariff.typeofcharge == 1 ? "Dinheiro" : "Porcentagem"}`}
                                                            </Typography>
                                                        </Box>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                setOpenTarifaDialog(true);
                                                                setDadosAcao(tariff);
                                                                setDescricao(tariff.description);
                                                                setTipoCobranca(tariff.typeofcharge == 1 ? 1 : 2);
                                                                setValor(tariff.value);
                                                            }}
                                                        >
                                                            Editar
                                                        </Button>
                                                    </Paper>
                                                </Grid>

                                                /*  <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                p: 1,
                                                border: '1px solid #ccc',
                                                borderRadius: 1,
                                                mb: 1,
                                            }}
                                        >
                                            <Box>
                                                <Typography variant="body1">
                                                    {`${tariff.description}`}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {tariff.typeofcharge == 1 ? "Valor: " + Formatar.converterCentavosParaReais(tariff?.value ? tariff.value : 0) : "Valor: " + Formatar.converterCentavosEmPorcentagem(tariff?.value ? tariff.value : 0) + '%'}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {`Tipo de cobrança: ${tariff.typeofcharge == 1 ? "Dinheiro" : "Porcentagem"}`}
                                                </Typography>
                                            </Box>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleDeletePixKey(tariff.id)}
                                                sx={{ ml: 2 }}
                                            >
                                                Editar
                                            </Button>
                                        </Box> */
                                            ))}
                                        </Grid>
                                    </Box>

                                </AccordionDetails>
                            </Accordion>


                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>KYC</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {/* Documento com Foto e a Pessoa (Frente) */}
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Documento com Foto e a Pessoa (Frente):</Typography>
                                                {userDetails?.docFaceBase64 ? (
                                                    <img src={`data:image/jpeg;base64,${userDetails?.docFaceBase64}`} alt="Documento com Foto e a Pessoa (Frente)" style={{ width: '100%', borderRadius: '5px' }} />
                                                ) : (
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleImageUpload(e, 'docFace')}
                                                    />
                                                )}
                                            </Paper>
                                        </Grid>

                                        {/* Foto Frontal da Pessoa */}
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Foto Frontal da Pessoa:</Typography>
                                                {userDetails?.face1Base64 ? (
                                                    <img src={`data:image/jpeg;base64,${userDetails?.face1Base64}`} alt="Foto Frontal da Pessoa" style={{ width: '100%', borderRadius: '5px' }} />
                                                ) : (
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleImageUpload(e, 'face1')}
                                                    />
                                                )}
                                            </Paper>
                                        </Grid>

                                        {/* Foto Lateral da Pessoa */}
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Foto Lateral da Pessoa:</Typography>
                                                {userDetails?.face2Base64 ? (
                                                    <img src={`data:image/jpeg;base64,${userDetails?.face2Base64}`} alt="Foto Lateral da Pessoa" style={{ width: '100%', borderRadius: '5px' }} />
                                                ) : (
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleImageUpload(e, 'face2')}
                                                    />
                                                )}
                                            </Paper>
                                        </Grid>

                                        {/* Documento (Frente) */}
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Documento de Identidade (Frente):</Typography>
                                                {userDetails?.frontBase64 ? (
                                                    <img src={`data:image/jpeg;base64,${userDetails?.frontBase64}`} alt="Documento (Frente)" style={{ width: '100%', borderRadius: '5px' }} />
                                                ) : (
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleImageUpload(e, 'front')}
                                                    />
                                                )}
                                            </Paper>
                                        </Grid>

                                        {/* Documento (Verso) */}
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Documento de Identidade (Verso):</Typography>
                                                {userDetails?.backBase64 ? (
                                                    <img src={`data:image/jpeg;base64,${userDetails?.backBase64}`} alt="Documento (Verso)" style={{ width: '100%', borderRadius: '5px' }} />
                                                ) : (
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleImageUpload(e, 'back')}
                                                    />
                                                )}
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12} mb={2}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleSendImageUpload(userDetails)}
                                            >
                                                {loading ? <CircularProgress size={24} /> : 'Atualizando o KYC'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            onClick={() => verificarStatusKYC(userDetails.accountData[0].paymentaccount)}
                                            disabled={loadingKYC}
                                        >
                                            {loadingKYC ? <CircularProgress size={24} /> : 'Verificar Status do KYC'}
                                        </Button>
                                    </Grid>

                                    {/* Exibição do status do KYC */}
                                    {kycStatus && (
                                        <Grid item xs={12}>
                                            <Typography variant="h6" color="textSecondary">
                                                Status do KYC: {kycStatus}
                                            </Typography>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>



                        </>
                    ) : (
                        <Typography>Carregando detalhes...</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMasterDialog}>Fechar</Button>
                </DialogActions>
            </Dialog >

            <TarifaDialog
                open={openTarifaDialog}
                onClose={fecharModalTariaPadrao}
                dadosAcao={dadosAcao}
                descricao={descricao}
                valor={valor}
                tipoDeCobranca={tipo_de_cobranca}
                onChangeValor={handleChange}
                isLoading={state.loadAlterarTarifaPadrao}
                onAlterarTarifa={alterarTarifa}
            />

            <IsencaoDialog
                open={openIsencaoDialog}
                onClose={handleCloseIsencaoDialog}
                tariff={tariff}
                casadeaposta={casadeaposta}
                login={login}
                password={password}
                motivo={motivo}
                selectedUserIsencao={selectedUserIsencao}
                onChangeAposta={(event) => setcasadeaposta(event.target.value)}
                onChangeLogin={(event) => setlogin(event.target.value)}
                onChangePassword={(event) => setpassword(event.target.value)}
                onChangeMotivo={(event) => setmotivo(event.target.value)}
                onSolicitarIsencao={handleSolicitarIsencaoDialog}
                formatDocumento={formatDocumento}
            />

            {/* <Dialog
                open={openTarifaDialog}
                onClose={() => fecharModalTariaPadrao()}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                fullWidth
                maxWidth="md" // Ajusta o tamanho do diálogo, pode ser "xs", "sm", "md", "lg", "xl"
            >
                <DialogTitle id="dialog-title">{"Editar " + dadosAcao.description}</DialogTitle>

                <DialogContent dividers>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                label="Descrição"
                                value={descricao}
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                label={tipo_de_cobranca == 1 ? "Valor R$" : " Valor %"}
                                value={valor}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                label="Tipo de cobrança"
                                value={tipo_de_cobranca == 1 ? "Dinheiro" : "Porcentagem"}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                            padding: '8px 16px'
                        }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            color="error"
                            sx={{
                                height: '40px',
                                fontSize: '0.875rem',
                                position: 'relative'
                            }}
                            onClick={fecharModalTariaPadrao}
                        >
                            Voltar
                        </Button>

                        <Button
                            size="small"
                            variant="contained"
                            sx={{
                                height: '40px',
                                fontSize: '0.875rem',
                                position: 'relative'
                            }}
                            color="success"
                            onClick={alterarTarifa}
                            disabled={state.loadAlterarTarifaPadrao}
                        >
                            {state.loadAlterarTarifaPadrao && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px'
                                    }}
                                />
                            )}
                            {state.loadAlterarTarifaPadrao ? 'Carregando...' : 'Alterar'}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
 */}

            {/* Dialog para visualizar o Extrato */}
            {/*         <Dialog open={openIsencaoDialog} onClose={handleCloseIsencaoDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Typography variant="h6" gutterBottom>
                        {'Tarifa para Solicitar Isenção ' + (() => {
                            if (Array.isArray(tariff)) {
                                const tarifaItem = tariff.find((item) => item.tariffkey === "tarifa_criar_pedido_isencao");
                                if (tarifaItem) {
                                    return tarifaItem.typeofcharge === 1
                                        ? Formatar.converterCentavosParaReais(tarifaItem.value)
                                        : Formatar.converterCentavosEmPorcentagem(tarifaItem.value) + '%';
                                }
                            }
                            return '';
                        })()}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box >
                        <TextField
                            size="small"
                            autoFocus
                            margin="dense"
                            name="casadeaposta"
                            label="Casa de Aposta"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={casadeaposta}
                            onChange={(event) => setcasadeaposta(event.target.value)}
                            required
                        />
                        <TextField
                            size="small"
                            margin="dense"
                            name="login"
                            label="Login"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={login}
                            onChange={(event) => setlogin(event.target.value)}
                            required
                        />
                        <TextField
                            size="small"
                            margin="dense"
                            name="password"
                            label="Senha"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={password}
                            onChange={(event) => setpassword(event.target.value)}
                            required
                        />
                        <TextField
                            size="small"
                            margin="dense"
                            name="motivo"
                            label="Motivo"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={motivo}
                            onChange={(event) => setmotivo(event.target.value)}
                            required
                        />
                        <TextField
                            size="small"
                            margin="dense"
                            label="CPF"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formatDocumento(selectedUserIsencao?.userData?.document)}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }} // Força a label a retraída
                        />
                        <TextField
                            size="small"
                            margin="dense"
                            label="Nome"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={selectedUserIsencao?.userData?.name}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }} // Força a label a retraída
                        />

                    </Box>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        onClick={handleCloseIsencaoDialog}
                        style={{ backgroundColor: 'red', color: 'white' }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        size="small"
                        onClick={handleSolicitarIsencaoDialog}
                        style={{ backgroundColor: 'green', color: 'white' }}
                    >
                        Solicitar
                    </Button>
                </DialogActions>


            </Dialog> */}

            {/* Snackbar para exibição de mensagens */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>

            {/* Loading Spinner */}
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

export default DataTable;
